import { Trans } from '@lingui/macro';
import PropTypes from "prop-types";
import React from "react";

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import BulkSelectBar from "@components/show_page/bulk_actions/BulkSelectBar";

import AssetCard from "../../asset/card/main";

import './styles/organization_search_results.scss';

export default class SearchResults extends React.Component {
  state = {
    brandfolders: [],
    assets: [],
    tags: [],
    selectedAssets: [],
    lastSelected: {},
    loading: true,
  };

  componentDidMount() {
    this.getAssets();
  }

  get imageRestrictedQuery() {
    return "extensions:(jpg OR jpeg OR png OR gif OR webp OR svg OR ai OR pdf OR tif OR tiff)";
  }

  get textRestrictedQuery() {
    return "asset_type:text";
  }

  get queryString() {
    const { query } = this.props;
    let updatedQuery;
    if (query && !query.includes(this.textRestrictedQuery) && BFG.embedView) {
      updatedQuery = encodeURI(`${query} AND ${this.imageRestrictedQuery}`);
    } else if (query) {
      updatedQuery = encodeURI(query);
    } else if (BFG.embedView) {
      updatedQuery = encodeURI(this.imageRestrictedQuery);
    } else {
      updatedQuery = null;
    }

    return updatedQuery ? `search=${updatedQuery}&` : null;
  }

  getAssets() {
    const base_url = `/api/v4/organizations/${this.props.org_key}/assets_search`;
    const strict_search = this.props.strict_search;
    const include = 'brandfolder,tags,section';
    const fields = 'is_downloadable,formatted_description,asset_data,created_at,html,updated_at,type,brandfolder_cdn_key,availability,availability_start,availability_end,site_id,attachment_count,background_color,best_link_for,cdn_url,design_huddle_editor_link,view_only';

    $.ajax({
      type: "POST",
      url: `${base_url}?${this.queryString}strict_search=${strict_search}&include=${include}&fields=${fields}&per=500`,
      beforeSend: (xhr) => {
        xhr.setRequestHeader("Authorization", `Bearer ${BF_Token}`);
      },
      success: (response) => {
        let tags = [];
        let brandfolders = [];
        const assets = response.data;
        if (response.included) {
          tags = response.included.filter((record) => (
            // Only returns manually generated tags
            record.type === "tags" && !record.attributes.auto_generated
          ));
          brandfolders = response.included.filter((record) => (record.type === "brandfolders"));
        }

        this.setState({
          assets,
          tags,
          brandfolders,
          loading: false
        });
      },
      error: () => { this.setState({ loading: false }); }
    });
  }

  // TODO: fix cmd+shift+a and cmd+shift+x
  selectAll = () => {
    this.setState((prevState) => ({ selectedAssets: prevState.assets.map((asset) => (asset.id)) }));
  }

  shiftSelect = (assetKey, position, viewOnlyAssetKeys) => {
    const lastSelectedPosition = this.state.lastSelected.position;
    if (Object.keys(this.state.lastSelected).length === 0) {
      this.toggleSelected({
        assetKeys: assetKey,
        viewOnlyAssetKeys
      });
    } else {
      const asset_ids = [...this.state.assets].map((asset) => (asset.id));
      if (position > lastSelectedPosition) {
        this.state.assets.forEach((asset) => {
          const assetPosition = asset_ids.indexOf(asset.id);
          const alreadyChecked = this.checkIndex(asset.id) > -1;

          if (assetPosition > lastSelectedPosition && assetPosition <= position) {
            this.toggleSelected({
              assetKeys: asset.id,
              viewOnlyAssetKeys
            });
          } else if (assetPosition === lastSelectedPosition && !alreadyChecked) {
            this.toggleSelected({
              assetKeys: asset.id,
              viewOnlyAssetKeys
            });
          }
        });
      } else if (position < lastSelectedPosition) {
        this.state.assets.forEach((asset) => {
          const assetPosition = asset_ids.indexOf(asset.id);
          const alreadyChecked = this.checkIndex(asset.id) > -1;

          if (assetPosition < lastSelectedPosition && assetPosition >= position) {
            this.toggleSelected({
              assetKeys: asset.id,
              viewOnlyAssetKeys
            });
          } else if (assetPosition === lastSelectedPosition && !alreadyChecked) {
            this.toggleSelected({
              assetKeys: asset.id,
              viewOnlyAssetKeys
            });
          }
        });
      } else if (position === lastSelectedPosition) {
        this.toggleSelected({
          assetKeys: assetKey,
          viewOnlyAssetKeys
        });
      }
      this.setState({ lastSelected: { assetKey, position } });
    }
  }

  clearSelected = () => {
    this.setState({
      lastSelected: {},
      selectedAssets: []
    });
  }

  toggleSelected = ({ assetKeys }) => {
    if (Array.isArray(assetKeys)) {
      this.setState({
        lastSelected: {},
        selectedAssets: []
      });
    } else {
      this.setState((prevState) => {
        const selectedAssets = [...prevState.selectedAssets];
        const index = this.checkIndex(assetKeys);
        if (index > -1) {
          selectedAssets.splice(index, 1);
        } else {
          selectedAssets.push(assetKeys);
        }

        return {
          lastSelected: { assetKey: assetKeys, position: 0 },
          selectedAssets
        };
      });
    }
  }

  checkIndex(assetKey) {
    return this.state.selectedAssets.indexOf(assetKey);
  }

  tagsFor(asset) {
    const tag_ids = asset.relationships.tags.data.map((tag) => (tag.id));
    const tags = this.state.tags.filter((tag) => (tag_ids.some((id) => (id === tag.id))));
    return tags;
  }

  brandfolderFor(asset) {
    return this.state.brandfolders.find((brandfolder) => (
      brandfolder.id === asset.relationships.brandfolder.data.id
    ));
  }

  renderAssetCards() {
    return (
      <article className="section-container">
        <div className="card-list">
          {this.state.assets.map((asset, i) => {
            const selected = this.checkIndex(asset.id) >= 0;
            const tags = this.tagsFor(asset);
            const brandfolder = this.brandfolderFor(asset);
            return (
              <AssetCard
                key={asset.id}
                asset={asset}
                brandfolder={brandfolder}
                libraryName={this.props.library_name}
                position={i}
                selected={selected}
                shiftSelect={this.shiftSelect}
                tags={tags}
                toggleSelected={this.toggleSelected}
              />
            );
          })}
        </div>
      </article>
    );
  }

  renderLoadingCards() {
    const screenWidth = window.innerWidth;
    const placeholderCards = Math.trunc(screenWidth / 340);
    const cards = [];

    for (let i = 0; i < placeholderCards; i += 1) {
      cards.push(
        <div key={`card-placeholder=${i}`} className="card-placeholder" />
      );
    }

    return <div className="card-list-loading">{cards}</div>;
  }

  renderBody() {
    const { assets, loading, selectedAssets } = this.state;

    if (loading) {
      return (
        <div className="organization-search-results-component">
          <article className="section-container">
            {this.renderLoadingCards()}
          </article>
        </div>
      );
    }

    // if all assets are downloadable, displayDownload === true
    const displayDownload = selectedAssets.every((assetKey) => (
      assets.find((asset) => asset.id === assetKey)?.attributes?.is_downloadable
    ));

    // since view only assets aren't supported on org page, consider all assets selected
    const selectedViewOnlyAssetKeys = new Set(assets.filter((asset) => asset?.attributes?.view_only).map((asset) => asset?.id));

    if (assets.length > 0) {
      return (
        <div className="organization-search-results-component">
          {this.renderAssetCards()}
          <BulkSelectBar
            addRemoveSelected={this.toggleSelected}
            allVisibleAssetKeys={assets.map((asset) => (asset.id))}
            approver={false}
            clearSelected={this.clearSelected}
            displayDownload={displayDownload}
            editable={false}
            orgSearchPage
            selectAllVisible={this.selectAll}
            selectedAssetKeys={new Set(selectedAssets)}
            selectedViewOnlyAssetKeys={selectedViewOnlyAssetKeys}
          />
        </div>
      );
    }

    return (
      <div className="organization-search-results-component">
        <div style={{ height: "100%", padding: "40px", marginTop: "73px" }}>
          <h4><Trans>No Results</Trans></h4>
        </div>
      </div>
    );
  }

  render() {
    return (
      <I18nProviderWrapper>
        {this.renderBody()}
      </I18nProviderWrapper>
    )
  }
}

SearchResults.propTypes = {
  library_name: PropTypes.string
};

SearchResults.defaultProps = {
  library_name: 'Brandfolder'
};
