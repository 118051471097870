import { t } from '@lingui/macro';

import {
  AvailabilityFilters,
  SearchOperatorOptions,
  SearchOption,
  StatusOption
} from '@components/show_page/sections/section_search/SearchTypes';

export const searchOptions = (isNarrowView = false): SearchOption[] => {
  const strictSearchEnabled = BFG.hasFeature('reenable_strict_sku_search');

  const options: SearchOption[] = [
    { label: isNarrowView ? t`Any` : t`Any of these terms`, value: SearchOperatorOptions.OR },
    { label: isNarrowView ? t`All` : t`All of these terms`, value: SearchOperatorOptions.AND },
    { label: isNarrowView ? t`Contains` : t`Contains this phrase`, value: SearchOperatorOptions.Quoted },
  ];

  if (strictSearchEnabled) {
    options.push({ label: isNarrowView ? t`Exact` : t`Exactly this phrase`, value: SearchOperatorOptions.Strict });
  }

  return options;
};

export const assetStatusOptions = (): StatusOption[] => ([
  { label: t`All`, value: 'all' },
  { label: t`Approved`, value: AvailabilityFilters.Approved },
  { label: t`Unapproved`, value: AvailabilityFilters.Unapproved },
  { label: t`Draft`, value: AvailabilityFilters.Unpublished },
  { label: t`Expired`, value: AvailabilityFilters.Expired }
]);
