import { FontIcons } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import PropTypes from "prop-types";
import React from 'react';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import LazyLoad from 'react-lazy-load';

import FontClass from "@components/asset/shared/fontClass";
import Checkbox from "@components/common/checkbox/main";
import Tooltip from '@components/common/tooltip/main';
import { customTemplatesVisitable } from '@components/design_huddles/editors/helpers';
import { FontIcon } from '@components/library/icon/index';
import { BrandfolderHexColors } from '@components/library/utils';
import BulkDeleteModal from "@components/show_page/bulk_actions/BulkDeleteModal";
import BulkShareModal from "@components/show_page/bulk_actions/BulkShareModal";
import { ViewOnlyIcon } from '@components/view-only/ViewOnlyIcon';
import { generateAssetDeepLink } from '@helpers/assets';
import { getAssetIsDesignHuddleProject, getAssetIsDesignHuddleTemplate } from '@helpers/design_huddle';
import oldType from '@helpers/oldType';
import { getResourceIsCollection } from '@helpers/resource';
import { Collection } from '@helpers/show_page_helpers';
import { getCurrentUserIsAnonymousOrGuest } from '@helpers/user';

import AssetActionsMenu from './asset_actions_menu';
import CardListView from './card_list_view';
import { WorkspaceTaskDetails } from './WorkspaceTaskDetails';

class Card extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      deleteModalShown: false,
      processingUrlTimeoutComplete: false, // used for newly created assets
      showTooltip: false,
      renderCardActions: false,
      shareModalShown: false,
    };

    this.assetNameContainer = React.createRef();
    this.container = React.createRef();
  }

  componentDidMount() {
    if (this.props.reorderable) {
      this.props.connectDragPreview(getEmptyImage(), {
        captureDraggingState: true,
      });
    }

    if (this.assetNameContainer?.offsetWidth) {
      const { offsetWidth, scrollWidth } = this.assetNameContainer;
      this.setState({ showTooltip: offsetWidth < scrollWidth });
    }

    if (this.props.asset.isNewAsset) {
      this.refreshCardThumbnail();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.processingUrlTimeout);
  }

  refreshCardThumbnail = () => {
    // use a "processing" image for card thumbnail for new assets for a short duration
    // after short duration, replace "processing" image with thumbnail url
    // this is a quick update to improve the current flow and have the processed
    // thumbnail image display without needing a page refresh

    // TODO: For a more comprehensive update- the best way to update this may be to
    // update the thumbnail image using sockets when the image is processed
    const quicklyProcessingExtensions = ['jpg', 'jpeg', 'png'];
    const timeoutDuration = quicklyProcessingExtensions.includes(
      this.props.asset.attributes.extension?.toLowerCase()
    ) ? 15000 : 30000;

    this.processingUrlTimeout = setTimeout(() => {
      this.setState({ processingUrlTimeoutComplete: true });
    }, timeoutDuration);
  };

  isCustomTemplateAsset = () => (!!this.props.asset.attributes.design_huddle_editor_link);

  isVisitableCustomTemplateAsset = () => (this.isCustomTemplateAsset() && customTemplatesVisitable());

  isPrintUITemplateAsset = () => (!!this.props.asset.attributes.printui_editor_link);

  isStoryteqTemplateAsset = () => (!!this.props.asset.attributes.storyteq_editor_link);

  isContentAutomationTemplateAsset = () => (!!this.props.asset.attributes.content_automation_editor_link);

  isVisitableTemplateAsset = () => (
    this.isVisitableCustomTemplateAsset()
    || this.isPrintUITemplateAsset()
    || this.isStoryteqTemplateAsset()
    || this.isContentAutomationTemplateAsset()
  );

  // eslint-disable-next-line max-len
  templateAsset = () => (this.isPrintUITemplateAsset() || this.isStoryteqTemplateAsset() || this.isCustomTemplateAsset() || this.isContentAutomationTemplateAsset());

  handleThumbnailError = (e) => {
    // Hacky, but don't want to implement state unless necessary
    const { asset, listView } = this.props;
    const { extension, name } = asset.attributes;
    const modifiedExtension = extension ? extension.replace('.', '') : '-';
    // eslint-disable-next-line max-len
    const source = (modifiedExtension) ? `${modifiedExtension}.svg` : `file.svg`;
    e.target.src = `${BFG.icon_service_url}/assets/types/${source}`;
    e.target.alt = name;
    if (!listView) {
      e.target.style.cssText = "padding: 25px;";
    }
  }

  createAndClickLink = () => {
    const { asset, section } = this.props;
    const {
      asset_data,
      content_automation_editor_link,
      design_huddle_editor_link,
      printui_editor_link,
      storyteq_editor_link,
    } = asset.attributes;

    const link = document.createElement("a");
    let href = asset_data.url;
    if (printui_editor_link) {
      href = printui_editor_link;
    } else if (storyteq_editor_link) {
      href = storyteq_editor_link;
    } else if (design_huddle_editor_link) {
      // NOTE: there's no section on the org page
      href = `${design_huddle_editor_link}${section ? `?section_key=${section.key}` : ''}`
    } else if (content_automation_editor_link) {
      href = `${content_automation_editor_link}`
    }

    if (BF_Manifest && this.templateAsset()) {
      // pass along manifest for authentication on link
      const url = new URL(`${window.location.origin}${href}`);
      const urlSearchParams = url.searchParams;
      urlSearchParams.set('digest', BF_Manifest);
      href = url.href;
    }

    link.id = `press-link-${asset.id}`; // give it an ID!
    link.href = href;
    link.target = "_blank";

    document.body.appendChild(link);
    const domLink = document.getElementById(link.id);
    domLink.click();
    document.getElementById(link.id).remove();
  }

  buttonHoverText = () => {
    const { asset } = this.props;
    const { type } = asset.attributes;
    if (asset.type === "Press"
      || type === "Press"
      || this.isVisitableTemplateAsset()
    ) {
      return <Trans>Visit</Trans>;
    }

    if (BFG.embedView && (asset.attributes.type === "texts" || asset.type === "texts")) {
      // Bubbles up to asset/card/main postEmbedMessageText function
      return <Trans>Select</Trans>;
    }

    return <Trans>View</Trans>;
  }

  renderGeneralThumbnail = () => {
    const { asset, listView } = this.props;
    const { name, background_color, availability, thumbnail_url } = asset.attributes;

    const { displayType, copy } = this.getBannerInfo();
    const style = background_color
      ? { backgroundColor: background_color, background: background_color }
      : { backgroundColor: "transparent" };
    const cdnLoaderURL = 'https://cdn.bfldr.com/27C9EC93/at/jgp7255xw8jcg79x68pk9pqt/Stripe-1s-44px.gif?auto=webp&format=gif';

    return (
      <div className="card-thumbnail" style={style}>
        {availability !== "available" && !listView ? this.bannerHTML(displayType, copy) : ""}
        <LazyLoad height="100%" offset="5000px 0px">
          <img
            alt={name}
            className={`image-div ${(thumbnail_url || '').toString().includes("/assets/types") ? "thumbnail_fallback" : ""}`}
            data-private
            onError={(e) => {
              if (typeof this.handleThumbnailError === "function") {
                this.handleThumbnailError(e);
              }
              this.handleThumbnailError = null;
            }}
            src={asset.isNewAsset && !this.state.processingUrlTimeoutComplete
              ? cdnLoaderURL
              : thumbnail_url
            }
          />
        </LazyLoad>
      </div>
    );
  }

  hasThumbnailOverride = (type) => {
    const { asset } = this.props;
    const { thumbnail_url } = asset?.attributes;
    const overrideType = ["Color", "colors", "Font", "fonts"].includes(type);
    // Types will return a url with "/assets/types/empty" unless thumbnail override has been set
    const hasThumbnail = thumbnail_url === true || !thumbnail_url?.includes("/assets/types/empty");
    return (overrideType && hasThumbnail);
  }

  renderAssetThumbnail = () => {
    const { asset, oldTypes } = this.props;
    const { name, availability } = asset.attributes;
    let { type } = asset.attributes;
    if (oldTypes) {
      type = asset.type;
    }

    if (this.hasThumbnailOverride(type)) {
      return this.renderGeneralThumbnail();
    }

    const { displayType, copy } = this.getBannerInfo();

    let colorHtml;
    switch (type) {
      case "Color":
      case "colors":
        if (asset.attributes) {
          colorHtml = asset.attributes.html;
        }

        if (colorHtml) {
          return (
            <div
              className="card-thumbnail"
              style={{ backgroundColor: colorHtml, background: colorHtml }}
            >
              {availability !== "available" ? this.bannerHTML(displayType, copy) : ""}
            </div>
          );
        }

        return (
          <div className="card-thumbnail">
            {availability !== "available" ? this.bannerHTML(displayType, copy) : ""}
          </div>
        );
      case "Font":
      case "fonts":
        return (
          <div className="card-thumbnail">
            {availability !== "available" ? this.bannerHTML(displayType, copy) : ""}
            <FontClass asset={asset} showPage>
              <Trans>Aa Bb</Trans>
            </FontClass>
          </div>
        );
      case "Text":
      case "texts":
        return (
          <div className="card-thumbnail" style={{ background: 'none', display: 'block' }}>
            {availability !== "available" ? this.bannerHTML(displayType, copy) : ""}
            <h4 className="asset-name">{name}</h4>
            {this.renderDescription()}
          </div>
        );
      default:
        return this.renderGeneralThumbnail();
    }
  }

  bannerHTML = (displayType, copy) => {
    return !!copy
      ? <div className={`status-ribbon ${displayType}`}> {copy} </div>
      : null;
  }

  getBannerInfo = () => {
    switch (this.props.asset.attributes.availability) {
      case "pending_approval": case "pending_approval_view_only":
        return this.renderPendingOrInProgress();
      case "unpublished": case "unpublished_view_only":
        return { copy: t`Draft`, displayType: 'unapproved-ribbon', color: BrandfolderHexColors.WarningRed };
      case "expired": case "expired_view_only":
        return { copy: t`Expired`, displayType: 'unapproved-ribbon', color: BrandfolderHexColors.WarningRed };
      default:
        return {};
    }
  }

  renderPendingOrInProgress = () => {
    switch (this.props.asset.attributes.task_status) {
      case "not_started":
        return { copy: t`Not started`, displayType: 'task-ribbon', color: BrandfolderHexColors.OrangeLight };
      case "in_progress":
        return { copy: t`In progress`, displayType: 'task-ribbon', color: BrandfolderHexColors.OrangeLight };
      case "completed":
      default:
        return { copy: t`Pending`, displayType: 'unapproved-ribbon', color: BrandfolderHexColors.WarningRed };
    }
  }

  renderDescription = () => {
    const { asset } = this.props;
    const { description } = asset.attributes;
    const formatted_description = (description && description.replace(/\n/g, "<br>")) || '';
    // USE THIS IF YOU WANT ABBREVIATED TEXT
    // let descriptionText = description && description.length > 770 ? description.substr(0, 770) + '...' : description;
    // let div = document.createElement("div");
    // div.innerHTML = descriptionText;
    // let text = div.textContent || div.innerText || "";
    //
    // return (
    //   <div className="description">
    //     {text}
    //   </div>);

    // USE THIS IF YOU WANT TO PRINT THE HTML
    return (
      <div
        dangerouslySetInnerHTML={{ __html: formatted_description }}
        className="description"
        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
      />
    );
  }

  handleRemoveSelectedAssetFromSet = () => {
    const { addRemoveSelected, asset, selectedAssetKeys, selectedViewOnlyAssetKeys } = this.props;

    const remainingAssetKeys = new Set(selectedAssetKeys);
    remainingAssetKeys.delete(asset.id);

    if (asset.attributes?.view_only) {
      const remainingViewOnlyAssetKeys = new Set(selectedViewOnlyAssetKeys);
      remainingViewOnlyAssetKeys.delete(asset.id);
      addRemoveSelected(remainingAssetKeys, remainingViewOnlyAssetKeys);
    } else {
      addRemoveSelected(remainingAssetKeys);
    }
  }

  actionsMenuItemsShown = () => {
    const { activeLabel, asset, editable, listView, section, downloadRequestId } = this.props;
    const canShare = BFG.context.hasFeature('share_links') && !BFG.showPageLite && (editable || !BFG.viewOnly);
    const canAnalyze = BFG.context.hasFeature('insights') && ['admin', 'owner'].includes(BF.fx.role());
    const downloadableSection = (section && !['Color', 'Text'].includes(section.type)) || BFG.resource.type === 'organization';

    const viewOnlyAssetsEnabled = BFG.hasFeature('view_only_assets');
    const hasViewOnlyAsset = viewOnlyAssetsEnabled && asset.attributes.view_only === true && !downloadRequestId;

    const downloadableAsset = !this.templateAsset()
      && asset.attributes.type !== 'ExternalMedium'
      && asset.attributes.type !== 'Font'
      && asset.attributes.is_downloadable !== false
      && !hasViewOnlyAsset
      && !BFG.viewOnly
      && !BFG.disableCollectionAdminDownload;

    return {
      canEdit: editable,
      canShare,
      canAnalyze,
      canDelete: editable && !Collection.key,
      canRemove: editable && Boolean(Collection.key),
      canUnlabel: editable && Boolean(activeLabel.key),
      canDownload: !!(downloadableSection && downloadableAsset),
      canOpen: listView,
    };
  }

  allowActionsMenu = () => {
    const actionsMenuItemsShown = this.actionsMenuItemsShown();

    // return true if at least one true in actionsMenuItemsShown object
    return Object.keys(actionsMenuItemsShown).map((item) => (
      actionsMenuItemsShown[item]
    )).some((item) => (item));
  }

  handleOpenModal = (modalType) => {
    if (modalType === 'share-modal') {
      this.setState({ shareModalShown: true });
    }

    if (modalType === 'delete-modal') {
      this.setState({ deleteModalShown: true });
    }
  }

  renderActions = () => {
    const { renderCardActions } = this.state;
    if (!renderCardActions) { return null; }

    const {
      activeLabel,
      asset,
      connectDragSource,
      reorderable,
      section,
      task
    } = this.props;

    return (
      <div className="asset-actions">
        <div className="resource-link">
          <div className="asset-hover-state">
            <button
              className="button secondary options-item"
              type="button"
            >
              {this.buttonHoverText()}
            </button>
          </div>
        </div>
        <div className="action-menu">
          <div className="left-side-actions">
            {!!reorderable && connectDragSource(
              <button
                className="reorder-button"
                type="button"
              >
                <FontIcon icon={FontIcons.DragIndicator} />
              </button>
            )}
          </div>
          {this.allowActionsMenu() && (
            <div
              className="right-side-actions"
              onClick={(e) => e.stopPropagation()}
              onKeyPress={(e) => e.stopPropagation()}
              role="button"
              tabIndex="0"
            >
              <AssetActionsMenu
                actionsMenuItemsShown={this.actionsMenuItemsShown()}
                activeLabel={activeLabel}
                asset={asset}
                handleOpenModal={this.handleOpenModal}
                handleRemoveSelectedAssetFromSet={this.handleRemoveSelectedAssetFromSet}
                section={section}
                task={task?.attributes}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  getExtentionIcon = (extension) => {
    switch (extension) {
      case "sketch":
        return <img alt="Sketch icon" height="15" src="https://cdn.bfldr.com/27C9EC93/at/xghm687fbv3bkgrkq2ptkp/sketch-icon.svg" style={{ marginBottom: "2px" }} />;
      case "bftemplate":
        return <FontIcon icon="template-editor" />;
      default:
        return extension;
    }
  }

  getDesignEditorIcon = (design_huddle_editor_link) => {
    if (getAssetIsDesignHuddleTemplate(design_huddle_editor_link)) {
      return <Trans>Template</Trans>;
    }
    return <Trans>Project</Trans>;
  }

  renderAttachmentInfo = () => {
    const {
      attachment_count,
      content_automation_editor_link,
      design_huddle_editor_link,
      extension,
    } = this.props.asset.attributes;

    // this is intentionally separate from extension below
    // so that we show this icon on org search
    if (design_huddle_editor_link) {
      return (
        <span className={classnames({
          'file-type-pill': true,
          'file-type-pill--template': getAssetIsDesignHuddleTemplate(design_huddle_editor_link),
          'file-type-pill--project': getAssetIsDesignHuddleProject(design_huddle_editor_link)
        })}>
          {this.getDesignEditorIcon(design_huddle_editor_link)}
        </span>
      )
    }

    if (content_automation_editor_link) {
      return (
        <span className={classnames('file-type-pill file-type-pill--template')}>
          <Trans>Template</Trans>
        </span>
      )
    }

    if (extension) {
      return (
        <span className="file-type-pill">
          {attachment_count > 1 ? (
            <>
              <FontIcon icon="paperclip" />
              {attachment_count > 100 ? "+" : attachment_count}
            </>
          ) : this.getExtentionIcon(extension)}
        </span>
      )
    }

    return null;
  }

  renderCustomFields = () => {
    const showCustomFieldsSection = getResourceIsCollection() && getCurrentUserIsAnonymousOrGuest(BFG.currentUser) ? BFG.showCustomFieldsOverviewTab : true;

    return (
      <>
        {showCustomFieldsSection && (
          <div className="custom-fields">
            {this.props.asset.attributes.prioritized_custom_fields.map(({ key, values }) => {
              const valuesAsString = values.map(({ value }) => value).join(', ');
              return (
                <div key={`${this.props.asset.id}${key.name}`} className="custom-fields__row">
                  <h5 className="custom-fields__row--item">
                    {key.name}:
                  </h5>
                  <h5 className="custom-fields__row--item" title={valuesAsString}>
                    {valuesAsString}
                  </h5>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  }

  renderCard = () => {
    const {
      allowCustomFields,
      asset,
      oldTypes,
      downloadRequestId,
      task,
      userViewOptions,
      viewAsset,
    } = this.props;
    const { attachment_count, extension, name, view_only } = asset.attributes;
    let { type } = asset.attributes;
    const hideAttachmentInfo = (extension === "empty" && attachment_count < 1)
      || type === "Text"
      || type === "texts";

    if (oldTypes) {
      type = asset.type;
    }

    return (
      <>
        <div
          className="card asset-card j-resource-card"
          id={asset.id}
          onClick={() => {
            if (type === "Press" || this.isVisitableTemplateAsset()) { this.createAndClickLink(); }
          }}
        >
          {this.renderAssetThumbnail()}
          {this.renderActions()}
        </div>
        <div className="description description-foot">
          <div className="flex-left">
            {BFG.embedView ? null : (
              <Checkbox
                checked={this.props.selected}
                size="md"
                toggle={this.props.handleSelectionClick}
              />
            )}
            {(type !== "Text" || type === "texts") ? (
              <button
                ref={(el) => { this.assetNameContainer = el; }}
                className="asset-name"
                onClick={(e) => {
                  if (type === "Press" || this.templateAsset()) {
                    viewAsset(asset.id);
                  } else {
                    this.props.handleSelectionClick(e);
                  }
                }}
                type="button"
              >
                {this.state.showTooltip ? (
                  <Tooltip
                    tooltipContent={asset.attributes.name}
                    tooltipId={asset.id}
                  >
                    {name}
                  </Tooltip>
                ) : (
                  name
                )}
              </button>
            ) : ''}
            {!hideAttachmentInfo ? this.renderAttachmentInfo() : ''}
            {BFG.hasFeature('view_only_assets') && view_only && !downloadRequestId && (
              <ViewOnlyIcon />
            )}
          </div>
        </div>
        {allowCustomFields && asset.attributes.prioritized_custom_fields.length > 0 && this.renderCustomFields()}
        {BFG.hasFeature('workspace') && BFG.resource.is_workspace && !!task && userViewOptions?.showTaskDetails && (
          <WorkspaceTaskDetails asset={asset.attributes} task={task} />
        )}
      </>
    );
  }

  handleVisitAsset = (e) => {
    const { alwaysOpenInModal, asset, listView, onVisitAsset } = this.props;
    const { type } = asset.attributes;

    if (e.ctrlKey || e.metaKey) {
      const href = generateAssetDeepLink({ assetKey: asset.id, slug: window.location.pathname });
      window.open(href, '_blank');
      return;
    }

    if (alwaysOpenInModal && !this.isVisitableCustomTemplateAsset()) {
      onVisitAsset(asset.id);
    } else if (!((this.isVisitableTemplateAsset() || type === "Press") && !listView)) {
      onVisitAsset(asset.id);
    }
  }

  render() {
    const {
      asset,
      connectDragSource,
      customFieldsKeys,
      editAsset,
      filtersDrawerOpen,
      handleSelectionClick,
      insertCaretDirection,
      isHovered,
      listView,
      onVisitAsset,
      reorderable,
      section,
      selected,
      downloadRequestId,
      task,
      userViewOptions,
      windowDimensions,
    } = this.props;

    const {
      availability,
      type
    } = asset.attributes;

    let insertCaret = '';
    if (isHovered && insertCaretDirection) {
      insertCaret = `select-${insertCaretDirection || ''}`;
    }

    return (
      <div
        ref={this.container}
        className={classnames(
          'base-card',
          'print-card',
          availability,
          insertCaret,
          `asset-type-${oldType(type)}`,
          { "li-large": type === "Text" },
        )}
        onClick={this.handleVisitAsset}
        onFocus={() => this.setState({ renderCardActions: true })}
        onMouseEnter={() => this.setState({ renderCardActions: true })}
        onMouseLeave={() => this.setState({ renderCardActions: false })}
        role="button"
        tabIndex="0"
      >
        {listView
          ? (
            <CardListView
              actionsMenuItemsShown={this.actionsMenuItemsShown()}
              allowActionsMenu={this.allowActionsMenu()}
              asset={asset}
              connectDragSource={connectDragSource}
              createAndClickLink={this.createAndClickLink}
              customFieldsKeys={customFieldsKeys}
              downloadRequestId={downloadRequestId}
              editAsset={editAsset}
              filtersDrawerOpen={filtersDrawerOpen}
              getBannerInfo={this.getBannerInfo}
              handleOpenModal={this.handleOpenModal}
              handleRemoveSelectedAssetFromSet={this.handleRemoveSelectedAssetFromSet}
              handleSelectionClick={handleSelectionClick}
              onVisitAsset={onVisitAsset}
              renderAssetThumbnail={this.renderAssetThumbnail}
              reorderable={reorderable}
              section={section}
              selected={selected}
              showCustomFields={userViewOptions.showCustomFields}
              task={task}
              windowDimensions={windowDimensions}
            />
          )
          : this.renderCard()
        }
        <div onClick={(e) => e.stopPropagation()}>
          {!!this.state.shareModalShown && (
            <BulkShareModal
              closeModal={() => this.setState({ shareModalShown: false })}
              selectedAssetKeys={new Set([asset.id])}
            />
          )}
          {!!this.state.deleteModalShown && (
            <BulkDeleteModal
              addRemoveSelected={this.handleRemoveSelectedAssetFromSet}
              closeModal={() => this.setState({ deleteModalShown: false })}
              selectedAssetKeys={new Set([asset.id])}
            />
          )}
        </div>
      </div>
    );
  }
}

Card.propTypes = {
  addRemoveSelected: PropTypes.func,
  allowCustomFields: PropTypes.bool,
  asset: PropTypes.shape({
    id: PropTypes.string,
    isNewAsset: PropTypes.bool,
    type: PropTypes.string,
    attributes: PropTypes.shape({
      asset_data: PropTypes.shape({}),
      attachment_count: PropTypes.number,
      availability: PropTypes.string,
      background_color: PropTypes.string,
      content_automation_editor_link: PropTypes.string,
      description: PropTypes.string,
      design_huddle_editor_link: PropTypes.string,
      extension: PropTypes.string,
      html: PropTypes.string,
      name: PropTypes.string,
      printui_editor_link: PropTypes.string,
      prioritized_custom_fields: PropTypes.arrayOf(PropTypes.shape({
        keys: PropTypes.shape({
          multi_value_enabled: PropTypes.bool,
          name: PropTypes.string,
          prioritized: PropTypes.bool,
        }),
        values: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
      })),
      storyteq_editor_link: PropTypes.string,
      task_status: PropTypes.oneOf(['not_started', 'in_progress', 'completed']),
      thumbnail_url: PropTypes.string,
      type: PropTypes.oneOf(['Color', 'ExternalMedium', 'Font', 'GenericFile', 'Person', 'Press', 'Text']),
      view_only: PropTypes.bool
    }),
  }),
  customFieldsKeys: PropTypes.arrayOf(PropTypes.string),
  editable: PropTypes.bool.isRequired,
  editAsset: PropTypes.func,
  handleSelectionClick: PropTypes.func.isRequired,
  listView: PropTypes.bool,
  selected: PropTypes.bool.isRequired,
  viewAsset: PropTypes.func,
  section: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.string,
  }),
  isHovered: PropTypes.bool,
  insertCaretDirection: PropTypes.oneOf(['', 'top', 'bottom', 'left', 'right']),
  onVisitAsset: PropTypes.func,
  reorderable: PropTypes.bool,
  alwaysOpenInModal: PropTypes.bool,
  activeLabel: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
  }),
  task: PropTypes.shape({
    attributes: PropTypes.shape({})
  }),
  userViewOptions: PropTypes.shape({
    showAsGrid: PropTypes.bool,
    sortOption: PropTypes.string,
    assetsPerPage: PropTypes.number,
    showCustomFields: PropTypes.bool,
    showTaskDetails: PropTypes.bool,
    showEmptySections: PropTypes.bool,
  }),
  downloadRequestId: PropTypes.number,
  /* eslint-disable react/no-unused-prop-types */
  connectDragSource: PropTypes.func,
  connectDragPreview: PropTypes.func,
  onDragComplete: PropTypes.func,
  /* eslint-enable react/no-unused-prop-types */
  selectedAssetKeys: PropTypes.instanceOf(Set),
  selectedViewOnlyAssetKeys: PropTypes.instanceOf(Set)
};

Card.defaultProps = {
  activeLabel: null,
  addRemoveSelected: undefined,
  allowCustomFields: false,
  alwaysOpenInModal: false,
  asset: {},
  connectDragPreview: () => {},
  connectDragSource: () => {},
  customFieldsKeys: undefined,
  downloadRequestId: null,
  insertCaretDirection: '',
  isHovered: false,
  listView: false,
  onDragComplete: () => {},
  onVisitAsset: () => {},
  reorderable: false,
  selectedAssetKeys: new Set(),
  selectedViewOnlyAssetKeys: new Set(),
  task: undefined,
  userViewOptions: {}
};

const dragSource = {
  beginDrag(props) {
    const { index, section, asset } = props;
    const { name, attachment_count: attachmentCount } = asset.attributes;

    return {
      index,
      id: asset.id,
      sectionId: section.key,
      name,
      attachmentCount,
      reorder: true,
    };
  },
  endDrag(props, monitor) {
    props.onDragComplete(monitor.getItem());
  },
};

const dragCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
  connectDragPreview: connect.dragPreview(),
});

export default DragSource('asset-reorder', dragSource, dragCollect)(Card);
export { Card as PlainCard };
