/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch } from 'react';
import { Tag } from 'react-tag-autocomplete';

import {
  Asset,
  AssetResponse,
  AssetTypes,
  ColorData,
  ExternalMediumData,
  FontData,
  GenericFileData,
  LinkObject,
  PersonData,
  PressData,
  Tag as AssetTag,
  WatermarkObject,
} from '@api/v4/assets/assetTypes';
import {
  CustomFieldKeysListResponse,
  CustomFieldKeysResponseObject,
  DependentCustomField
} from '@api/v4/resources/CustomFieldKeysTypes';
import { TaskClient } from '@api/v4/tasks';
import { AttachmentResponseObject } from '@api/v4/attachments/attachmentTypes';
import {
  CreateCustomFieldParams,
  CustomFieldValue,
  CustomFieldValuesListData,
} from '@api/v4/assets/customFieldTypes';
import { Locale } from '@components/common/language_menu/languagesMap';
import { UploaderSources } from '@api/uploaders';

/** ATTACHMENT TYPES */
export interface FlattenedAttachment {
  extension: string;
  filename: string;
  key: string;
  mimetype: string;
  size: number;
  temporaryAttachment: boolean;
  url: string;
  height?: number;
  position?: number;
  thumbnailUrl?: string;
  width?: number;
  source?: UploaderSources;
}

export type AttachmentActionDispatch = Dispatch<ReducerAction<AttachmentPayloads>>;

export interface RepositionAttachmentPayload {
  draggedIndex: number;
  hoveredIndex: number;
}

interface AttachmentPayload {
  index: number;
}

export interface AttachmentDetailsPayload extends AttachmentPayload {
  filename: string;
}

export interface ReplaceAttachmentPayload extends AttachmentPayload {
  replacement: FlattenedAttachment;
}

interface DeleteAttachmentKeyPayload {
  attachmentKey: string;
}

export interface UploadNewAttachmentPayload {
  attachments: FlattenedAttachment[];
}

type AttachmentPayloads =
  | AttachmentDetailsPayload
  | AttachmentPayload
  | AttachmentResponseObject
  | DeleteAttachmentKeyPayload
  | ReplaceAttachmentPayload
  | RepositionAttachmentPayload
  | UploadNewAttachmentPayload;

export interface PrintUI {
  id: number;
  ogranization_id: number;
  section_id: number;
  client: string;
  auth: string;
  active: boolean;
  key: string;
  approvals: boolean;
  created_at: string;
  updated_at: string;
  data: {
    printuiSettings: unknown;
  };
}
/** END ATTACHMENT TYPES */

/** FONT ASSET TYPES */
export interface WebFonts {
  [value: string]: {
    label: string;
    type: 'google' | 'adobe';
  };
}
/** END FONT ASSET TYPES */

/** ASSET TYPES */
export type ReactTag = (AssetTag & Tag);

type InitializeAssetPayload = InitializePayload<AssetResponse>;
export type AssetDetailsPayload = Partial<ModalEditFormContentState>;
export type TaskDetailsPayload = Partial<ModalEditFormContentState>;
export type AssetDetailsDispatch = Dispatch<ReducerAction<AssetDetailsPayload>>;
export type TaskDetailsDispatch = Dispatch<ReducerAction<TaskDetailsPayload>>;
/** END ASSET TYPES */

/** THUMBNAIL OVERRIDE TYPES */
export enum ThumbnailOverridePayloadOperations {
  Update,
  Delete
}

export interface ThumbnailOverridePayload extends LinkObject {
  operation: ThumbnailOverridePayloadOperations;
}

export type ThumbnailOverrideDispatch = Dispatch<ReducerAction<ThumbnailOverridePayload>>;
/** END THUMBNAIL OVERRIDE TYPES */

/** WATERMARK TYPES */
export enum WatermarkPayloadOperations {
  Update,
  Delete
}

export interface WatermarkPayload extends WatermarkObject {
  operation: WatermarkPayloadOperations;
}

export type WatermarkDispatch = Dispatch<ReducerAction<WatermarkPayload>>;
/** END WATERMARK TYPES */

/** CUSTOM FIELD TYPES */
export enum CustomFieldPayloadOperations {
  Create,
  Update,
  Delete
}

export type CreateCustomFieldPayload = Partial<CreateCustomFieldParams> & {
  operation: CustomFieldPayloadOperations;
  index?: number;
};

export interface UpdateCustomFieldKeyPayload {
  customFieldKeyId: string;
  customFieldKeyName: string;
}

export interface UpdateCustomFieldValuesPayload {
  operation: CustomFieldPayloadOperations;
  customFieldKeyId: string;
  customFieldValue: CustomFieldValue;
  customFieldKeyName?: string;
  customFieldRequired?: boolean;
  customFieldTouched?: boolean; // needed for required custom field validation/error state
}

export interface DeleteCustomFieldValuesPayload {
  customFieldKeyId: string;
  customFieldRequired?: boolean;
  customFieldTouched?: boolean; // needed for required custom field validation/error state
  deleteKey?: boolean;
}

export interface FlatCustomFieldMaps {
  key: {
    id: string;
    name: string;
    prioritized: boolean;
    allowedValues: string[];
    restricted: boolean;
  };
  value: {
    id: string | null;
    value: string;
  };
}

export interface DeleteCustomFieldPayload {
  customFieldValueKey: string;
}

export interface CustomFieldKeyValuesEntry {
  customFieldKey: {
    id: string;
    name: string;
  };
  customFieldValues: CustomFieldValue[];
  customFieldRequired?: boolean;
  customFieldTouched?: boolean; // needed for required custom field validation/error state
}
export interface FlattenedCustomFieldKeyValuesMap {
  [customFieldKeyId: string]: CustomFieldKeyValuesEntry;
}

type InitializeCustomFieldValuesPayload = InitializePayload<CustomFieldValuesListData>;
export type InitialCustomFieldValuesDataDispatch = Dispatch<ReducerAction<InitializeCustomFieldValuesPayload>>;
export type CreateCustomFieldsDispatch = Dispatch<ReducerAction<CreateCustomFieldPayload>>;
export type UpdateCustomFieldValuesDispatch = Dispatch<ReducerAction<UpdateCustomFieldValuesPayload>>;
export type UpdateCustomFieldKeyDispatch = Dispatch<ReducerAction<UpdateCustomFieldKeyPayload>>;
export type DeleteCustomFieldValuesDispatch = Dispatch<ReducerAction<DeleteCustomFieldValuesPayload>>;

type InitializeCustomFieldKeysPayload = InitializePayload<CustomFieldKeysListResponse>;
export type InitialCustomFieldKeysDispatch = Dispatch<ReducerAction<InitializeCustomFieldKeysPayload>>;

type CustomFieldPayloads =
  | CreateCustomFieldPayload
  | DeleteCustomFieldPayload
  | DeleteCustomFieldValuesPayload
  | UpdateCustomFieldKeyPayload
  | UpdateCustomFieldValuesPayload;

/** END CUSTOM FIELD TYPES */

/** LOCALE TYPES */
export interface LocalePayload {
  ugtLocale: Locale | '';
}
/** END LOCALE TYPES */

/** REDUCER+STATE TYPES */
export interface ModalEditFormContentState {
  aiGeneratedCaptionId: string | null;
  assetDescription: string;
  assetName: string;
  attachments: FlattenedAttachment[];
  colorData: ColorData | null;
  externalMediumData: ExternalMediumData | null;
  firstAttachmentKey: string;
  flattenedCustomFieldKeyValuesMap: FlattenedCustomFieldKeyValuesMap;
  fontData: FontData | null;
  genericFileData: GenericFileData | null;
  personData: PersonData | null;
  pressData: PressData | null;
  tags: ReactTag[];
  tagSearchSuggestions: ReactTag[] | null; /* null is unset */
  task: TaskClient | null;
  thumbnailUrl: string;
  thumbnailOverride: LinkObject;
  terribleTrumbowygFlag: Locale | ''; // flag to notify trumbowyg when to reinitialize after locale change call comes back
  watermark: WatermarkObject;
}

export interface InitialModalEditFormContentState {
  asset: Asset | null;
  assetKey: string;
  assetType: AssetTypes;
  attachments: FlattenedAttachment[];
  customFieldKeys: CustomFieldKeysResponseObject[] | null;
  dependentCustomFields: DependentCustomField[] | null;
  flattenedCustomFieldKeyValuesMap: FlattenedCustomFieldKeyValuesMap;
  printui: PrintUI;
  tags: AssetTag[];
  task: TaskClient | null;
}

export interface ReducerState {
  editState: ModalEditFormContentState | null;
  errorState: ValidationErrors[];
  initialData: InitialModalEditFormContentState | null;
  ugtLocale: Locale | '';
}

export enum ActionTypes {
  DeleteCustomFieldValues,
  DeleteAttachment,
  FetchCustomFieldKeys,
  FetchCustomFieldValues,
  Initialize,
  NewAsset,
  NewTask,
  ReplaceAttachment,
  RepositionAttachment,
  ResolveAsset,
  ResolveAttachmentUpdate,
  ResolveDeletedAttachment,
  ResolveDeletedCustomField,
  ResolveTags,
  ThumbnailOverrideAction,
  UpdateAsset,
  UpdateTask,
  UpdateAttachmentDetails,
  UpdateCustomFieldKey,
  UpdateCustomFieldValues,
  UpdateLocale,
  UploadNewAttachment,
  ValidationError,
  WatermarkAction,
}

export enum ValidationErrors {
  DescriptionMaxLengthExceeded,
  InvalidDueDate,
  InvalidHexValue,
  InvalidKeyName,
  MissingAssetName,
  MissingDescription,
  MissingFontInput,
  MissingMediaUrl,
  MissingRequiredCustomFields
}

export interface ReducerAction<T> {
  type: ActionTypes;
  payload?: T;
}

interface InitializePayload<
  T =
  | AssetResponse
  | CustomFieldKeysResponseObject[]
  | CustomFieldValuesListData
  | CustomFieldKeysListResponse
> {
  response: T;
  printui?: PrintUI;
}

interface NewAssetPayload {
  assetType: AssetTypes;
}

interface NewTaskPayload {
  assetType: AssetTypes;
}

export interface ResolveAssetPayload {
  asset: Asset;
  newAttachmentKeys: string[];
}

export type ModalEditFormReducerAction = ReducerAction<
  | { validationErrors: ValidationErrors[] }
  | AssetDetailsPayload
  | AttachmentPayloads
  | CustomFieldPayloads
  | InitializePayload
  | LocalePayload
  | NewAssetPayload
  | NewTaskPayload
  | ResolveAssetPayload
  | TaskDetailsPayload
  | ThumbnailOverridePayload
  | WatermarkPayload
>;

export enum Submit {
  DeletedAsset, // 0
  DeletedAttachment, // 1
  DeletedCustomFieldValue, // 2
  NewAsset, // 3
  NewCustomFields, // 4
  NewCustomFieldKeyValuePair, // 5
  NewCustomFieldValues, // 6
  NewTask, // 7
  Tags, // 8
  UpdatedAsset, // 9
  UpdatedAttachment, // 10
  UpdatedCustomField, // 11
  UpdatedCustomFieldValue, // 12
  UpdatedTask // 13
}

/** Type predicates */
export const isInitialAction = (action: ModalEditFormReducerAction): action is ReducerAction<InitializeAssetPayload> => (
  action.type === ActionTypes.Initialize
);

export const isNewAssetAction = (action: ModalEditFormReducerAction): action is ReducerAction<NewAssetPayload> => (
  action.type === ActionTypes.NewAsset
);

export const isNewTaskAction = (action: ModalEditFormReducerAction): action is ReducerAction<NewTaskPayload> => (
  action.type === ActionTypes.NewTask
);

export const isRepositionAttachmentAction = (action: ModalEditFormReducerAction): action is ReducerAction<RepositionAttachmentPayload> => (
  action.type === ActionTypes.RepositionAttachment
);

export const isUploadNewAttachment = (action: ModalEditFormReducerAction): action is ReducerAction<UploadNewAttachmentPayload> => (
  action.type === ActionTypes.UploadNewAttachment
);

export const isAttachmentDetailsAction = (action: ModalEditFormReducerAction): action is ReducerAction<AttachmentDetailsPayload> => (
  action.type === ActionTypes.UpdateAttachmentDetails
);

export const isReplaceAttachmentAction = (action: ModalEditFormReducerAction): action is ReducerAction<ReplaceAttachmentPayload> => (
  action.type === ActionTypes.ReplaceAttachment
);

export const isDeleteAttachmentAction = (action: ModalEditFormReducerAction): action is ReducerAction<AttachmentPayload> => (
  action.type === ActionTypes.DeleteAttachment
);

export const isAssetDetailsAction = (action: ModalEditFormReducerAction): action is ReducerAction<AssetDetailsPayload> => (
  action.type === ActionTypes.UpdateAsset
);

export const isTaskDetailsAction = (action: ModalEditFormReducerAction): action is ReducerAction<TaskDetailsPayload> => (
  action.type === ActionTypes.UpdateTask
);

export const isFetchCustomFieldKeysAction = (action: ModalEditFormReducerAction): action is ReducerAction<InitializeCustomFieldKeysPayload> => (
  action.type === ActionTypes.FetchCustomFieldKeys
);

export const isFetchCustomFieldValuesListAction = (action: ModalEditFormReducerAction): action is ReducerAction<InitializeCustomFieldValuesPayload> => (
  action.type === ActionTypes.FetchCustomFieldValues
);

export const isUpdateCustomFieldKeyAction = (action: ModalEditFormReducerAction): action is ReducerAction<UpdateCustomFieldKeyPayload> => (
  action.type === ActionTypes.UpdateCustomFieldKey
);

export const isUpdateCustomFieldValuesAction = (action: ModalEditFormReducerAction): action is ReducerAction<UpdateCustomFieldValuesPayload> => (
  action.type === ActionTypes.UpdateCustomFieldValues
);

export const isDeleteCustomFieldValuesAction = (action: ModalEditFormReducerAction): action is ReducerAction<DeleteCustomFieldValuesPayload> => (
  action.type === ActionTypes.DeleteCustomFieldValues
);

export const isValidationErrorAction = (action: ModalEditFormReducerAction): action is ReducerAction<{ validationErrors: ValidationErrors[] }> => (
  action.type === ActionTypes.ValidationError
);

export const isThumbnailOverrideAction = (action: ModalEditFormReducerAction): action is ReducerAction<ThumbnailOverridePayload> => (
  action.type === ActionTypes.ThumbnailOverrideAction
);

export const isWatermarkAction = (action: ModalEditFormReducerAction): action is ReducerAction<WatermarkPayload> => (
  action.type === ActionTypes.WatermarkAction
);

export const isResolveDeletedAttachmentAction = (action: ModalEditFormReducerAction): action is ReducerAction<DeleteAttachmentKeyPayload> => (
  action.type === ActionTypes.ResolveDeletedAttachment
);

export const isResolveDeletedCustomFieldValueAction = (action: ModalEditFormReducerAction): action is ReducerAction<DeleteCustomFieldPayload> => (
  action.type === ActionTypes.ResolveDeletedCustomField
);

export const isResolveAssetAction = (action: ModalEditFormReducerAction): action is ReducerAction<ResolveAssetPayload> => (
  action.type === ActionTypes.ResolveAsset
);

export const isResolveAttachmentUpdateAction = (action: ModalEditFormReducerAction): action is ReducerAction<AttachmentResponseObject> => (
  action.type === ActionTypes.ResolveAttachmentUpdate
);

export const isUpdateLocaleAction = (action: ModalEditFormReducerAction): action is ReducerAction<LocalePayload> => (
  action.type === ActionTypes.UpdateLocale
);

/** END REDUCER+STATE TYPES */
