import { t } from '@lingui/macro';

import { fetchJson } from '@api/ApiHelper';
import { DEFAULT_FILESTACK_POST_OPTIONS } from '@api/v4/private/resources/fs_creds';

export const round = (numberToRound, decimalPlaces) => (
  Math.round(numberToRound * (10 ** decimalPlaces)) / (10 ** decimalPlaces)
);

export const cleanExtension = (extension) => (extension.replace('.', '').toUpperCase());

export const processExtensions = (extension = 'PNG', conversion_options = []) => {
  // clean mimetypes, e.g. 'image/jpeg' => jpeg
  const extensionSansSlash = extension.includes('/') ? extension.split('/')[1] : extension;

  // 'new Set' ensure uniqueness
  return [...new Set([...conversion_options, cleanExtension(extensionSansSlash)])];
};

export const processImageDimensions = (attachment, canvasData) => {
  let fullSizeHeight = canvasData.naturalHeight;
  let fullSizeWidth = canvasData.naturalWidth;
  const { cropping_image_height, cropping_image_width } = attachment.attributes;

  const isSquare = (fullSizeWidth / fullSizeHeight) === 1;
  const isDisplayedAsLandscape = (fullSizeWidth / fullSizeHeight) > 1;
  const isMetadataLandscape = cropping_image_width / cropping_image_height > 1;

  if (isSquare || isDisplayedAsLandscape === isMetadataLandscape) {
    fullSizeHeight = cropping_image_height;
    fullSizeWidth = cropping_image_width;
  } else {
    // if browser rotates image to display, flip the height & width provided by metadata
    fullSizeHeight = cropping_image_width;
    fullSizeWidth = cropping_image_height;
  }

  return { fullSizeHeight, fullSizeWidth };
};

export const categories = () => [
  { label: t`Freeform`, value: 'freeform' },
  { label: t`Social Media`, value: 'social_media' },
  { label: t`Advertising`, value: 'advertising_media' },
  { label: t`Custom`, value: 'custom' },
];

export const socialMediaOptions = [
  { label: 'Facebook', value: 'facebook' },
  { label: 'Twitter', value: 'twitter' },
  { label: 'LinkedIn', value: 'linkedin' },
  { label: 'Instagram', value: 'instagram' },
  { label: 'Pinterest', value: 'pinterest' },
];

export const advertisingMediaOptions = [
  { label: 'Desktop', value: 'desktop' },
  { label: 'Mobile', value: 'mobile' },
];

export const defaultCroppedData = {
  height: 0,
  rotate: 0,
  scaleX: 0,
  scaleY: 0,
  width: 0,
  x: 0,
  y: 0,
};

export const defaultImage = {
  extension: null,
  filename: null,
  height: null,
  size: null,
  updated_at: null,
  url: null,
  width: null,
};

export const defaultPresets = () => [
  {
    id: "facebook_profile",
    type: "conversion_presets",
    name: t`Profile (180 x 180)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 180,
    width: 180,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "facebook_cover",
    type: "conversion_presets",
    name: t`Profile Cover Photo (820 x 312)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 312,
    width: 820,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "facebook_feed", /* there's no feed in helpers.ts */
    type: "conversion_presets",
    name: t`Feed (1200 x 630)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 630,
    width: 1200,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "facebook_event",
    type: "conversion_presets",
    name: t`Event Cover (1920 x 1005)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 1005,
    width: 1920,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "facebook_story",
    type: "conversion_presets",
    name: t`Story (1080 x 1920)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 1920,
    width: 1080,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "facebook_landscape_post", /* there's no feed in helpers.ts */
    type: "conversion_presets",
    name: t`Landscape Post (1200 x 630)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 630,
    width: 1200,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "facebook_portrait_post",
    type: "conversion_presets",
    name: t`Portrait Post (1200 x 630)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 630,
    width: 1200,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "facebook_square_post",
    type: "conversion_presets",
    name: t`Square Post (1200 x 1200)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 1200,
    width: 1200,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "instagram_profile",
    type: "conversion_presets",
    name: t`Profile (110 x 110)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 110,
    width: 110,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "instagram_thumbnail", /* there's no thumbnail in helpers.ts */
    type: "conversion_presets",
    name: t`Thumbnail (161 x 161)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 161,
    width: 161,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "instagram_feed", /* there's no feed in helpers.ts */
    type: "conversion_presets",
    name: t`Feed (1080 x 1080)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 1080,
    width: 1080,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "instagram_story",
    type: "conversion_presets",
    name: t`Story (1080 x 1920)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 1920,
    width: 1080,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "instagram_landscape_post",
    type: "conversion_presets",
    name: t`Landscape Post (1080 x 566)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 566,
    width: 1080,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "instagram_portrait_post",
    type: "conversion_presets",
    name: t`Portrait Post (1080 x 1350)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 1350,
    width: 1080,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "instagram_square_post",
    type: "conversion_presets",
    name: t`Square Post (1080 x 1080)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 1080,
    width: 1080,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "linkedin_profile",
    type: "conversion_presets",
    name: t`Profile (300 x 300)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 300,
    width: 300,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "linkedin_background", /* there's no background in helpers.ts */
    type: "conversion_presets",
    name: t`Background (1584 x 396)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 396,
    width: 1584,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "linkedin_logo",
    type: "conversion_presets",
    name: t`Logo (300 x 300)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 300,
    width: 300,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "linkedin_square_logo", /* there's no square logo in helpers.ts */
    type: "conversion_presets",
    name: t`Square Logo (60 x 60)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 60,
    width: 60,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "linkedin_cover",
    type: "conversion_presets",
    name: t`Cover (1584 x 396)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 396,
    width: 1584,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "linkedin_banner", /* there's no banner in helpers.ts */
    type: "conversion_presets",
    name: t`Banner (646 x 220)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 220,
    width: 646,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "linkedin_hero", /* there's no hero in helpers.ts */
    type: "conversion_presets",
    name: t`Hero (1128 x 376)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 376,
    width: 1128,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "linkedin_shared", /* there's no shared in helpers.ts */
    type: "conversion_presets",
    name: t`Shared (1104 x 736)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 736,
    width: 1104,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "linkedin_landscape_post",
    type: "conversion_presets",
    name: t`Landscape Post (1200 x 627)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 627,
    width: 1200,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "linkedin_portrait_post",
    type: "conversion_presets",
    name: t`Portrait Post (627 x 1200)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 1200,
    width: 627,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "linkedin_story",
    type: "conversion_presets",
    name: t`Story (1080 x 1920)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 1920,
    width: 1080,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "linkedin_company_cover",
    type: "conversion_presets",
    name: t`Company Cover (1128 x 191)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 191,
    width: 1128,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "pinterest_profile",
    type: "conversion_presets",
    name: t`Profile (165 x 165)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 165,
    width: 165,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "pinterest_pin", /* there's no pin in helpers.ts */
    type: "conversion_presets",
    name: t`Pin (freeform)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: null,
    width: null,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "pinterest_portrait_post",
    type: "conversion_presets",
    name: t`Portrait Post (600 x 900)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 900,
    width: 600,
    format: null,
    pad: null,
    crop_gravity: null,
  },
   {
    id: "pinterest_square_post",
    type: "conversion_presets",
    name: t`Square Post (600 x 600)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 600,
    width: 600,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "twitter_profile",
    type: "conversion_presets",
    name: t`Profile (400 x 400)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 400,
    width: 400,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "twitter_header", /* there's no header in helpers.ts */
    type: "conversion_presets",
    name: t`Header (1500 x 500)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 500,
    width: 1500,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "twitter_instream", /* there's no tweeted image in helpers.ts */
    type: "conversion_presets",
    name: t`Tweeted Image (1200 x 675)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 675,
    width: 1200,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "twitter_landscape_post",
    type: "conversion_presets",
    name: t`Landscape Post (675 x 1200)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 1200,
    width: 675,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "twitter_story",
    type: "conversion_presets",
    name: t`Story (1080 x 1920)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 1920,
    width: 1080,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "twitter_cover_photo",
    type: "conversion_presets",
    name: t`Cover Photo (1500 x 500)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 500,
    width: 1500,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "desktop_medium_banner",
    type: "conversion_presets",
    name: t`Medium Banner (300 x 250)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 250,
    width: 300,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "desktop_leaderboard",
    type: "conversion_presets",
    name: t`Leaderboard (728 x 90)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 90,
    width: 728,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "desktop_wide_skyscraper",
    type: "conversion_presets",
    name: t`Wide Skyscraper (160 x 600)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 600,
    width: 160,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "desktop_halfpage",
    type: "conversion_presets",
    name: t`Halfpage (300 x 600)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 600,
    width: 300,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "desktop_large_leaderboard",
    type: "conversion_presets",
    name: t`Large Leaderboard (970 x 90)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 90,
    width: 970,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "desktop_billboard",
    type: "conversion_presets",
    name: t`Billboard (970 x 250)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 250,
    width: 970,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "desktop_large_rectangle",
    type: "conversion_presets",
    name: t`Large Rectangle (336 x 280)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 280,
    width: 336,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "desktop_skyscraper",
    type: "conversion_presets",
    name: t`Skyscraper (120 x 600)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 600,
    width: 120,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "desktop_small_square",
    type: "conversion_presets",
    name: t`Small Square (200 x 200)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 200,
    width: 200,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "desktop_square",
    type: "conversion_presets",
    name: t`Square (250 x 250)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 250,
    width: 250,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "desktop_vertical_banner",
    type: "conversion_presets",
    name: t`Vertical Banner (120 x 240)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 240,
    width: 120,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "desktop_full_banner",
    type: "conversion_presets",
    name: t`Full Banner (468 x 60)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 60,
    width: 486,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "desktop_half_banner",
    type: "conversion_presets",
    name: t`Half Banner (234 x 60)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 60,
    width: 234,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "mobile_leaderboard",
    type: "conversion_presets",
    name: t`Leaderboard (320 x 50)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 50,
    width: 320,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "mobile_full_page_flex",
    type: "conversion_presets",
    name: t`Full Page Flex (320 x 320)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 320,
    width: 320,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "mobile_large_banner",
    type: "conversion_presets",
    name: t`Large Banner (320 x 100)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 100,
    width: 320,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "mobile_square",
    type: "conversion_presets",
    name: t`Square (250 x 250)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 250,
    width: 250,
    format: null,
    pad: null,
    crop_gravity: null,
  },
  {
    id: "mobile_small_square",
    type: "conversion_presets",
    name: t`Small Square (200 x 200)`,
    aspect_ratio: null,
    aspect_width: null,
    aspect_height: null,
    height: 200,
    width: 200,
    format: null,
    pad: null,
    crop_gravity: null,
  },
];

export const templateCroppingPreset = ({ aspectRatio, height, width }) => (
  {
    id: "template_cropping",
    type: "conversion_presets",
    name: t`Template Cropping`,
    aspect_ratio: aspectRatio,
    aspect_width: width,
    aspect_height: height,
    height,
    width,
    format: null,
    pad: null,
    crop_gravity: null,
  }
);

export const convertWithFilestack = async (conversionParams, extension, imageUrl, printuiAssetKey = null, printuiDigestKey = null, download = true) => {
  let errorMessage;
  if (!conversionParams.height || !conversionParams.width || conversionParams.height <= 0 || conversionParams.width <= 0) {
    errorMessage = t`Width and height must be greater than zero.`;
  }
  if (conversionParams.height * conversionParams.width > 100000000) {
    errorMessage = t`Image too large for conversion. Please use a smaller image.`;
  }
  if (conversionParams.height > 10000 || conversionParams.width > 10000) {
    errorMessage = t`Width or height exceeds 10,000 pixels. Please use a smaller image.`;
  }
  if (errorMessage) {
    return [null, null, errorMessage];
  }

  const filestackCredentials = await fetchJson({
    body: {
      ...DEFAULT_FILESTACK_POST_OPTIONS,
      ...printuiAssetKey && { printui_asset_key: printuiAssetKey },
      ...printuiDigestKey && { printui_digest_key: printuiDigestKey }
    },
    method: 'POST',
    url: `/api/v4/private/${BFG.resource.type}s/${BFG.resource.key}/fs_creds`
  });
  const key = filestackCredentials.data.key;

  // https://www.filestack.com/docs/api/processing/#image-transformations
  const outputExtension = ["jpg", "jpeg"].includes(extension?.toLowerCase()) ? "jpg" : "png";
  const negativeXOffset = conversionParams.crop_x_offset && conversionParams.crop_x_offset < 0 ? -1 * conversionParams.crop_x_offset : 0;
  const negativeYOffset = conversionParams.crop_y_offset && conversionParams.crop_y_offset < 0 ? -1 * conversionParams.crop_y_offset : 0;
  const xOffset = conversionParams.crop_x_offset && conversionParams.crop_x_offset > 0 ? parseInt(conversionParams.crop_x_offset, 10) : 0;
  const yOffset = conversionParams.crop_y_offset && conversionParams.crop_y_offset > 0 ? parseInt(conversionParams.crop_y_offset, 10) : 0;
  const cropArray = [xOffset, yOffset, conversionParams.width - negativeXOffset, conversionParams.height - negativeYOffset];
  const cropFunction = `crop=dim:${JSON.stringify(cropArray)}`;
  const outputFunction = `output=f:${outputExtension}`;
  const process_path = `/${cropFunction}/${outputFunction}/rotate=deg:exif/${imageUrl}`;
  const conversionFunctions = [
    { name: "watermark", params: { file: `https://process.filestackapi.com/${key}${process_path}` } },
    { name: "output", params: { format: outputExtension } },
  ];
  if (download) {
    conversionFunctions.push({ name: "content", params: { type: "attachment", filename: `cropped_file.${outputExtension}` } });
  }

  // const backgroundImage = `https://dummyimage.com/${conversionParams.width}x${conversionParams.height}/ffffff00/ffffff00.${outputExtension}`;
  // const backgroundImage = `https://cdn.brandfolder.io/N924WO31/as/4fjf68t86s6mkjt6kb9pw/transparent.${outputExtension}?width=${conversionParams.width}&height=${conversionParams.height}&crop_width=${conversionParams.width}&crop_height=${conversionParams.height}&quality=1`;
  // https://brandfolder.com/templating-qa#!asset/4fjf68t86s6mkjt6kb9pw  - Asset in Brandfolder (do not delete)
  // NOTE: cropping is required to bypass Brandfolder's SmartCDN logic (otherwise you will always get a square based on the original image).
  const backgroundImage = `https://cdn.brandfolder.io/GE0HDUPX/at/ksfrfx84cpp3nt3xjs7g4j/transparent.${outputExtension}?format=${outputExtension}&width=${conversionParams.width}&height=${conversionParams.height}&crop=${conversionParams.width},${conversionParams.height}`;
  const processingUrl = `https://cdn.fs.brandfolder.com/${key}/b64/${urlSafeBase64(conversionFunctions)}/${backgroundImage}`;
  // Attempt to warm the Fastly cache so that Filestack has greater reliability during its conversion
  // NOTE: app/javascript/components/advanced_download/download_button.jsx button must be inactive longer than this setting
  const backgroundCacheWarmTimeout = 8000;
  try {
    await fetch(backgroundImage, { method: 'HEAD', signal: AbortSignal.timeout(backgroundCacheWarmTimeout) }).then(response => {
      // Attempt to warm the cache, don't really care about response
    });
  } catch (error) {
    if (error.name === 'TimeoutError') {
      console.log(`BF SmartCDN cache warming initial request ${backgroundCacheWarmTimeout}ms timeout, still attempting conversion`) // Still try, don't error
    }
  }
  return [processingUrl, outputExtension, null];
};

// returns a base64 string of obj that is safe to use in a URL (i.e. no / or + characters)
const urlSafeBase64 = (obj) => {
  return btoa(JSON.stringify(obj)).replace(/\//g, "_").replace(/\+/g, "-");
};
