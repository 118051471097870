import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { ThumbnailImageComponentImageProps } from 'react-grid-gallery';

import { AssetTypes, ColorData, isCompatibleThumbnailAssetType } from '@api/v4/assets/assetTypes';

import FontClass from '@components/asset/shared/fontClass';

import { AssetViewModel } from './model';
import classes from './styles/attachment-gallery.module.scss';
import './styles/attachment-gallery.module.scss';

export interface GalleryImageProps {
  imageProps?: ThumbnailImageComponentImageProps;
  item: {
    asset: AssetViewModel;
    attachmentId?: string;
    handleExpandedViewSelection?: (asset: AssetViewModel) => void;
    handleSelection?: (id: string) => void;
    isSelected?: boolean;
  };
}

type AssetThumbnailSignature = ((asset: AssetViewModel) => JSX.Element);

export const GalleryImage: FunctionComponent<GalleryImageProps> = ({
  imageProps,
  item: galleryItem,
}) => {
  const { type } = galleryItem.asset;

  const renderCompatibleAssetThumbnail = ({ alt, src, style }: ThumbnailImageComponentImageProps): JSX.Element => (
    <img
      alt={alt}
      className={classes.imgThumbnail}
      src={src}
      style={style || null}
    />
  );

  const renderColor = (asset: AssetViewModel): JSX.Element => {
    const { hex } = asset.attributes.asset_data as ColorData;

    return (
      <div
        className={classes.cardThumbnail}
        style={{
          backgroundColor: `#${hex}`,
          background: `#${hex}`,
        }}
      />
    );
  };

  const renderFont = (asset: AssetViewModel): JSX.Element => (
    <FontClass
      asset={asset}
      className={classes.fontDiv}
      showPage
    >
      <Trans>Aa Bb</Trans>
    </FontClass>
  );

  const renderText = (asset: AssetViewModel): JSX.Element => {
    const { description, name } = asset.attributes;

    const formattedDescription = (description && description.replace(/\n/g, '<br>')) || '';

    return (
      <div className={classes.textCardThumbnail}>
        <h4 className={classes.textCardAssetName}>{name}</h4>
        <div
          // eslint-disable-next-line @typescript-eslint/naming-convention
          dangerouslySetInnerHTML={{ __html: formattedDescription }}
          className={classes.textThumbnailDescription}
        />
      </div>
    );
  };

  const assetThumbnails: { [assetType: string]: AssetThumbnailSignature } = {
    [AssetTypes.Color]: renderColor,
    [AssetTypes.Font]: renderFont,
    [AssetTypes.Text]: renderText,
  };

  return (
    <>
      { isCompatibleThumbnailAssetType(type) && imageProps?.src
        ? renderCompatibleAssetThumbnail(imageProps)
        : assetThumbnails[type](galleryItem.asset)
      }
    </>
  );
};
