import { UploaderSources } from '@api/uploaders';
import {
  ReducerState, FlattenedAttachment,
} from '@components/asset/modal/tabs/edit/EditTabTypes';
import { isDirty } from '@components/asset/modal/tabs/edit/helpers';

export interface CreateAttachmentProperties {
  filename: string;
  key: string;
  position: number;
  url: string;
  source?: UploaderSources
}

export interface UpdatedAttachmentData {
  key: string;
  url?: string;
  filename?: string;
  position?: number;
}

interface AttachmentChanges {
  deletedAttachmentKeys: string[];
  updatedAttachments: UpdatedAttachmentData[];
}

enum AttachmentChangeValues {
  Delete,
  Update
}

const getDeletedAttachments = (changes: Partial<AttachmentChanges>, state: ReducerState): Partial<AttachmentChanges> => {
  const { editState, initialData } = state;
  const deletedAttachmentKeys: string[] = initialData.attachments
    .filter((initialAttachment) => !editState.attachments.find(({ key }) => key === initialAttachment.key))
    .map((deletedAttachment) => deletedAttachment.key);

  return deletedAttachmentKeys.length > 0
    ? {
      ...changes,
      deletedAttachmentKeys
    }
    : changes;
};

const getUpdatedAttachments = (changes: Partial<AttachmentChanges>, state: ReducerState): Partial<AttachmentChanges> => {
  const { editState, initialData } = state;
  const updatedAttachments: UpdatedAttachmentData[] = [];
  editState.attachments.forEach((editAttachment) => {
    const matchingAttachment = initialData.attachments.find((initialAttachment) => initialAttachment.key === editAttachment.key);
    if (matchingAttachment) {
      if (isDirty<FlattenedAttachment>(matchingAttachment, editAttachment)) {
        const attachmentChanges: UpdatedAttachmentData = { key: editAttachment.key };
        ['url', 'filename', 'position'].forEach((property) => {
          if (editAttachment[property] !== matchingAttachment[property]) {
            attachmentChanges[property] = editAttachment[property];
          }
        });
        if (attachmentChanges.url && !attachmentChanges.filename) {
          attachmentChanges.filename = editAttachment.filename;
        }
        updatedAttachments.push(attachmentChanges);
      }
    }
  });

  return updatedAttachments.length > 0
    ? {
      ...changes,
      updatedAttachments
    }
    : changes;
};

const attachmentChangeMap = {
  [AttachmentChangeValues.Delete]: getDeletedAttachments,
  [AttachmentChangeValues.Update]: getUpdatedAttachments
};

const getAttachmentChanges = (state): Partial<AttachmentChanges> | false => {
  let changes: Partial<AttachmentChanges> = {};
  changes = Object.keys(attachmentChangeMap).reduce((prevChanges, key) => attachmentChangeMap[key](prevChanges, state), changes);
  return Object.keys(changes).length > 0 && changes;
};

export default getAttachmentChanges;
