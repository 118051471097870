import { fetchJson } from '@api/ApiHelper';
import { t, Trans } from '@lingui/macro';
import React, { useState, FunctionComponent, SyntheticEvent } from 'react';
import { Form } from 'react-bootstrap';

import {
  deleteCustomFieldKey,
} from '@api/v4/custom-field-keys';
import { submitNewCustomFields } from '@api/v4/resources/custom_fields';
import {
  CustomFieldKeyClient,
  CustomFieldKeysResponseObject
} from '@api/v4/resources/CustomFieldKeysTypes';
import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import Tooltip from '@components/common/tooltip/main';
import { PrimaryButton, SecondaryButton } from '@components/library/button';
import { CustomFieldRow } from '@components/settings/brandfolder/custom-fields-tab/CustomFieldRow';
import { CustomFieldsEvents } from '@components/settings/brandfolder/custom-fields-tab/CustomFieldsFormEnums';
import {
  CustomFieldsUpdated,
  DisplayedCustomFieldKey,
  UpdateRowArguments
} from '@components/settings/brandfolder/custom-fields-tab/CustomFieldsFormTypes';
import { containsSpecialCharacters } from '@helpers/custom-field-validation';

interface CustomFieldsFormProps {
  brandfolderKey: string;
  fetchCustomFieldKeys: () => Promise<void>;
  rows: DisplayedCustomFieldKey[];
  setRows: SetStateDispatch<DisplayedCustomFieldKey[]>;
}

const blankCustomFieldKeyEntry: CustomFieldKeyClient = {
  allowedValues: [],
  multiValueEnabled: false,
  name: '',
  required: false
};

export const CustomFieldsForm: FunctionComponent<CustomFieldsFormProps> = ({
  brandfolderKey,
  fetchCustomFieldKeys,
  rows,
  setRows,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [customFieldsToDelete, setCustomFieldsToDelete] = useState<string[]>([]);
  const ugtLocale = determineUGTLocale();

  const validName = (customFieldName: string): boolean => {
    const customFieldNames = rows.filter((key) => !!key.name);
    const duplicates = customFieldNames.filter(({ name }) => name.trim() === customFieldName?.trim()).length >= 2;
    return !!customFieldName && customFieldName.trim().length > 0 && !duplicates && !containsSpecialCharacters(customFieldName);
  };

  const validForm = (): boolean => {
    const customFieldNames = rows.map((key) => key.name);
    const emptyNames = customFieldNames.filter((name) => !!name === false).length > 0;
    const uniqueNames = Array.from(new Set(customFieldNames));
    const containsDuplicates = uniqueNames.length < customFieldNames.length;
    const hasSpecialCharacters = customFieldNames.some((name) => containsSpecialCharacters(name));
    return !emptyNames && !containsDuplicates && !hasSpecialCharacters;
  };

  const updateRow = ({
    allowedValues,
    index,
    multiValueEnabled,
    name,
    required
  }: UpdateRowArguments): void => {
    setRows((prevState) => {
      const updatedState = [...prevState];
      if (index !== undefined) {
        updatedState[index] = {
          ...updatedState[index],
          ...allowedValues !== undefined && { allowedValues },
          ...multiValueEnabled !== undefined && { multiValueEnabled },
          ...name !== undefined && { name },
          ...required !== undefined && { required }
        };
      }
      return updatedState;
    });
  };

  const removeRow = (index: number): void => {
    const updatedCustomFieldKeys = [...rows];
    const fieldToDelete = updatedCustomFieldKeys.splice(index, 1)[0];
    setCustomFieldsToDelete((prevState) => (fieldToDelete.key ? [...prevState, fieldToDelete.key] : [...prevState]));

    const customFieldsForm = document.getElementById('custom-fields-form');
    if (customFieldsForm) {
      customFieldsForm.setAttribute('data-locked', 'true');
    }

    setRows(updatedCustomFieldKeys);
  };

  const handleSubmitCustomFieldKeys = (): Promise<unknown> => {
    const existingCustomFieldKeys = rows.filter((row) => !!(row.key));
    const attributes = existingCustomFieldKeys.map(({ allowedValues, multiValueEnabled, name, required, key }) => ({
      allowed_values: allowedValues, multi_value_enabled: multiValueEnabled, name: name?.trim(), required, key
    }));

    return fetchJson({
      url: 'api/v4/private/custom_field_keys',
      method: 'PATCH',
      body: {
        data: {
          attributes,
          brandfolder_key: brandfolderKey
        }
      }
    });
  };

  const handleSubmitNewCustomFieldKeys = (): Promise<CustomFieldKeysResponseObject[]> => {
    const newCustomFieldKeys = rows.filter((row) => (!row.key));
    const attributes = newCustomFieldKeys.map(({ allowedValues, multiValueEnabled, name, required }) => ({
      allowed_values: allowedValues, multi_value_enabled: multiValueEnabled, name: name?.trim(), required
    }));
    return submitNewCustomFields({
      attributes,
      resourceKey: brandfolderKey,
      resourceType: 'brandfolder',
      ugtLocale
    });
  };

  const resolveFormSubmission = (): void => {
    setCustomFieldsToDelete([]);
    setSubmitting(false);
    fetchCustomFieldKeys();
    Notify.create({
      title: t`Controlled Custom Fields Updated`,
      type: 'success'
    });

    const customFieldsSettingsUpdated = new CustomEvent<CustomFieldsUpdated>(CustomFieldsEvents.SettingsUpdated, {
      detail: {
        refresh: true
      }
    });
    window.dispatchEvent(customFieldsSettingsUpdated);
  };

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    const customFieldsForm = document.getElementById('custom-fields-form');
    if (customFieldsForm) {
      customFieldsForm.setAttribute('data-locked', 'false');
    }

    if (!validForm()) {
      Notify.create({
        title: t`Please fix invalid fields before submitting`,
        body: t`Key names may not contain colons, quotation marks, or periods`,
        type: 'error'
      });
    } else if (!submitting) {
      setSubmitting(true);
      try {
        const deletePromises = customFieldsToDelete.map((customFieldKey) => (deleteCustomFieldKey({ customFieldKey })));
        await Promise.all(deletePromises);
        await handleSubmitCustomFieldKeys();
        await handleSubmitNewCustomFieldKeys();
        resolveFormSubmission();
      } catch (err) {
        setSubmitting(false);
        // Generally when people swap names for custom fields ex: A.name => B.name
        Notify.create({
          title: t`An error occured and not all custom fields were updated.`,
          body: t`Please try again. Names may have been swapped and conflict with one another.`,
          type: 'error'
        });
      }
    }
  };

  return (
    <div className="container j-controlled-custom-fields">
      <Form id="custom-fields-form" onSubmit={submitForm}>
        <div className="form-headers">
          <h4 className="header-copy column-1"><Trans>Key</Trans></h4>
          <h4 className="header-copy column-2">
            <Trans>
              Values <span className="column-span">(separate with semicolons)</span>
            </Trans>
          </h4>
          <div className="column-checkbox column-3">
            <h4 className="header-copy"><Trans>Allow multiple values</Trans></h4>
            <Tooltip
              tooltipContent={t`If checked, asset keys can be assigned one or more of the values listed. If unchecked, asset keys can only be assigned one of the values listed.`}
              tooltipId="control-custom-fields-info"
            >
              <span className="bff-tool-tip" />
            </Tooltip>
          </div>
          <div className="column-checkbox column-4">
            <h4 className="header-copy"><Trans>Required</Trans></h4>
          </div>
          <div aria-hidden className="column-5" role="presentation" />
        </div>
        <div className="brandfolder-settings__control-custom-fields--list">
          {rows.map((displayedCustomFieldKey, index) => (
            <CustomFieldRow
              key={displayedCustomFieldKey.key || index}
              displayedCustomFieldKey={displayedCustomFieldKey}
              index={index}
              removeRow={removeRow}
              updateRow={updateRow}
              valid={validName(displayedCustomFieldKey.name)}
            />
          ))}
          {!rows.length && (
            <p className="empty-rows-copy">
              <Trans>No custom fields to display. Click "Add new Custom Field" below to get started!</Trans>
            </p>
          )}
        </div>
        <div className="add-custom-field-button">
          <SecondaryButton
            icon="bff-plus"
            onClick={(): void => setRows((prevState) => ([...prevState, blankCustomFieldKeyEntry]))}
          >
            <Trans>Add new Custom Field</Trans>
          </SecondaryButton>
        </div>
        <PrimaryButton
          className="custom-field-update-button j-modal-submit-button"
          isLoading={submitting}
          loadingCopy={t`Updating Custom Fields`}
          size="large"
          type="submit"
        >
          <Trans>Update Custom Fields</Trans>
        </PrimaryButton>
      </Form>
    </div>
  );
};
