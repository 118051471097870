import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, MutableRefObject } from 'react';

import { TaskPriorities } from '@api/v4/tasks';
import { ListDropdown, ListOption, ListOptionValue } from '@components/library/dropdown/index';
import { FontIcon, FontIcons } from '@components/library/icon';

import classes from './styles/workspace-task-dropdowns.module.scss';

interface WorkspaceTaskPriorityDropdownProps {
  id: string
  onChange: (listOption: ListOption) => void;
  value: ListOption | ListOptionValue;
  className?: string;
  overflowParentRef?: MutableRefObject<HTMLElement>;
  showAllOptions?: boolean;
}

export const WorkspaceTaskPriorityDropdown: FunctionComponent<WorkspaceTaskPriorityDropdownProps> = (props) => {
  const { className, id, onChange, overflowParentRef, showAllOptions, value } = props;

  const priorityOptions: ListOption[] = [
    {
      label: (
        <>
          <FontIcon
            aria-hidden
            className={classnames(
              classes['bf-icon'],
              classes['low-icon']
            )}
            icon={FontIcons.ArrowDown}
          />
          <span><Trans>Low</Trans></span>
        </>
      ),
      value: TaskPriorities.Low
    },
    {
      label: (
        <>
          <FontIcon
            aria-hidden
            className={classnames(
              classes['bf-icon'],
              classes['medium-icon']
            )}
            icon={FontIcons.PriorityMedium}
          />
          <span><Trans>Medium</Trans></span>
        </>
      ),
      value: TaskPriorities.Medium
    },
    {
      label: (
        <>
          <FontIcon
            aria-hidden
            className={classnames(
              classes['bf-icon'],
              classes['high-icon']
            )}
            icon={FontIcons.PriorityHigh}
          />
          <span><Trans>High</Trans></span>
        </>
      ),
      value: TaskPriorities.High
    },
  ];

  const priorityAllOption: ListOption = {
    label: <Trans>All</Trans>,
    value: TaskPriorities.All
  };

  return (
    <ListDropdown
      className={classnames(
        classes['bf-dropdown__list'],
        className
      )}
      id={id}
      onChange={onChange}
      options={showAllOptions ? [priorityAllOption, ...priorityOptions] : priorityOptions}
      overflowParentRef={overflowParentRef}
      value={value}
      virtualizeOptions={false}
    />
  );
};
