import { ListOption } from '@components/library/dropdown';

export enum FileTypes {
  JPEG = 'jpeg',
  PDF = 'pdf',
  PNG = 'png'
}

export interface CanvaListOption extends ListOption {
  fileType: FileTypes;
}

export enum TemplateTypes {
  A4Document,
  Announcement,
  Brochure,
  BusinessCard,
  Card,
  EtsyShopCover,
  EtsyShopIcon,
  FacebookAd,
  FacebookAppAd,
  FacebookCover,
  FacebookEventCover,
  FacebookPost,
  Flyer,
  GiftCertificate,
  Infographic,
  InstagramPost,
  InstagramStory,
  Invitation,
  Invoice,
  Label,
  LargeRectangleAd,
  LeaderboardAd,
  Letter,
  LinkedInBanner,
  Logo,
  MediumRectangleAd,
  Menu,
  MindMap,
  Newsletter,
  PhotoCollage,
  PinterestGraphic,
  Postcard,
  Poster,
  Presentation,
  Presentation43,
  ProductLabel,
  RecipeCard,
  Resume,
  SnapchatGeofilter,
  SocialMedia,
  Ticket,
  TwitterHeader,
  TwitterPost,
  WideSkyscraperAd,
  YouTubeChannelArt,
  YouTubeThumbnail,
}

/**
 * https://docs.developer.canva.com/button/javascript/api-reference/create-design
 */
export interface CanvaButtonApiV2 {
  createDesign: (
    opts: {
      design: {
        type: string;
        dimensions?: {
          height?: number;
          units?: string;
          width?: number;
        };
        title?: string;
      };
      editor?: {
        fileType?: FileTypes; // defaults to png
        publishLabel?: string;
      };
      type: string; // the api describes design.type, but type is also needed at this level
      onDesignClose?: () => void;
      onDesignOpen?: (opts: { designId: string; designTitle: string }) => void;
      onDesignPublish?: (opts: { designId: string; designTitle: string; exportUrl: string; }) => void;
    }
  ) => void;
}

export interface CanvaCredentials {
  key: string;
}
export interface CanvaCredentialsPostResponse {
  data: CanvaCredentials;
}
