import { FontIcon, FontIcons } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { isGettyClient } from '@helpers/getty-strings';

import classes from './styles/deprecation-banner.module.scss';

interface DeprecationInfoBannerProps {
  learnMoreLink: string;
  gettyLearnMoreLink?: string;
  storageKey: string;
  messageType: DeprecationMessageType;
}

export enum DeprecationMessageType {
  EXACT_AND_SKU_SEARCH_DEPRECATION = 'EXACT_AND_SKU_SEARCH_DEPRECATION',
}

const messageMap: Record<DeprecationMessageType, string> = {
  [DeprecationMessageType.EXACT_AND_SKU_SEARCH_DEPRECATION]: t`Changes are coming to the Brandfolder search experience. "Exactly this phrase" and SKU Search will be removed on Mar 12, 2025.`,
};

export const DeprecationInfoBanner: FunctionComponent<DeprecationInfoBannerProps> = ({
  gettyLearnMoreLink, learnMoreLink, storageKey, messageType 
}) => {
  useEffect(() => {
    if (localStorage.getItem(storageKey) === null) {
      localStorage.setItem(storageKey, 'true');
    }
  }, [storageKey]);

  const [show, setShow] = useState(localStorage.getItem(storageKey) !== 'false');

  const strictSearchEnabled = BFG.hasFeature('reenable_strict_sku_search');
  const messageTypeEnum = messageType as DeprecationMessageType;
  const message = messageMap[messageTypeEnum];
  const linkToDisplay = isGettyClient() && gettyLearnMoreLink ? gettyLearnMoreLink : learnMoreLink;

  const handleDismiss = (): void => {
    setShow(false);
    localStorage.setItem(storageKey, 'false');
  };

  if (!show || !strictSearchEnabled) return null;

  return (
    <I18nProviderWrapper>
      <div className={classes.deprecationAnnouncement}>
        <div className={classes.deprecationAnnouncementInnerLeft}>
          <FontIcon
            aria-hidden
            className={classes.deprecationAnnouncementInfoIcon}
            icon={FontIcons.Info}
            iconSize={18}
          />
          <p className={classes.deprecationAnnouncementText}>
            {message}
            <a
              className={classes.deprecationAnnouncementLink}
              href={linkToDisplay}
              rel='noreferrer'
              target='_blank'
            >
              <Trans>Learn More</Trans>
            </a>
          </p>
        </div>
        <FontIcon
          aria-hidden
          className={classes.deprecationAnnouncementButton}
          icon={FontIcons.Close}
          iconSize={14}
          onClick={handleDismiss}
        />
      </div>
    </I18nProviderWrapper>
  );
};
