/* eslint-disable no-param-reassign */
import { Result } from '@uppy/transloadit';
import { SyntheticEvent } from 'react';

import { StructuredFileMetadata, UploaderSources } from '@api/uploaders';
import { ColorData, AssetTypes } from '@api/v4/assets/assetTypes';
import { sortOnPrioritizedThenName } from '@api/v4/assets/helpers';
import {
  CustomFieldKeysListResponse,
  CustomFieldKeysResponseObject,
  DependentCustomField,
  SplitParentAndChildCustomFieldKeys
} from '@api/v4/resources/CustomFieldKeysTypes';
import { TaskPriorities, TaskStatuses } from '@api/v4/tasks';
import languagesMap, { LanguageMapEntry, Locale, findCurrentLanguageInMap } from '@components/common/language_menu/languagesMap';
import { getStorage, StorageTypes } from '@helpers/storage';

import {
  CustomFieldKeyValuesEntry,
  FlattenedAttachment,
  FlattenedCustomFieldKeyValuesMap,
  InitialModalEditFormContentState,
  ModalEditFormContentState,
  Submit,
  UpdateCustomFieldValuesPayload
} from './EditTabTypes';

export const transformColorData = (colorData: ColorData): ColorData => {
  // make sure we submit fully qualified color data
  const transformedColorData = {
    ...colorData
  };

  const selfOrDefault = (key: string, defaultValue: string): void => {
    transformedColorData[key] = colorData[key] || defaultValue;
  };

  const setMissingDefaults = (keyList: string[], defaultValue: string): void => {
    // if at least one of the values exists (is not an empty string), assign the other
    // values that are empty strings to the default value
    if (keyList.reduce((prev, key) => prev || colorData[key], false)) {
      keyList.forEach((key) => selfOrDefault(key, defaultValue));
    }
  };

  setMissingDefaults(['r', 'g', 'b'], '0');
  setMissingDefaults(['c', 'm', 'y', 'k'], '100');

  if (colorData.hex && colorData.hex.length === 3) {
    // get full hex string from shorthand
    // e.g. #1fe == #11ffee
    let qualifiedHexString = '';
    for (let i = 0; i < 3; i += 1) {
      qualifiedHexString += colorData.hex.substring(i, i + 1).repeat(2);
    }
    transformedColorData.hex = qualifiedHexString;
  }

  return transformedColorData;
};

export const isValidHex = (hex: string): boolean => {
  const hexRegex = /^(?:[0-9A-F]{3}|[0-9A-F]{6})$/i;
  return hexRegex.test(hex);
};

/**
 * Get whether data is dirty/changed.
 * When "a" is not null (editing existing data), this compares initial state "a" to changed state "b".
 * When "a" is null (creating new data), this checks that all "b" values aren't empty, null, or undefined.
 * TODO: "a" should be refactored to always be an initial default/empty object and shouldn't be null
 * @param {T | null} a T | null
 * @param {T} b T
 * @returns {boolean} boolean
 */
export const isDirty = <T>(a: T | null, b: T): boolean => {
  // if both "a" and "b" are falsey, data is not dirty
  if (!a && !b) {
    return false;
  }

  // if we are adding a new asset, "a" will be null
  // this means we can't compare "a" against "b" to handle our isDirty checks
  // we instead need to check if any "b" keys have value
  // (assuming an object that initially has empty strings or null/undefined values)
  if (a === null) {
    // we use some here because it stops the loop once we have a key that has a value
    // TODO: this won't work with changing numeric or boolean values, see refactor TODO above
    return Object.values(b).some((bValue) => bValue !== '' && bValue !== null && bValue !== undefined);
  }

  // else we are editing, so we can compare "a" to "b" below

  // if both "a" and "b" are not objects
  if (!(a instanceof Object && b instanceof Object)) {
    // if non-object "a" does not equal non-object "b"
    if (a !== b) {
      return true;
    }
    return false;
  }

  // "a" and "b" have different number of object keys, data is dirty
  if (Object.keys(a).length !== Object.keys(b).length) {
    return true;
  }

  let dirty = false;
  // compare object "a" values to object "b" values, data is dirty if any value doesn't match
  // TODO: consider using a package: react-fast-compare, fast-deep-equal, or fast-equals
  if (Object.keys(a).length > 0) {
    dirty = Object.keys(a).reduce((prev, aKey) => prev || a[aKey] !== b[aKey], dirty);
    if (!dirty) {
      dirty = Object.keys(b).reduce((prev, bKey) => prev || a[bKey] !== b[bKey], dirty);
    }
  }

  return dirty;
};

export const getAvailableLanguages = (): LanguageMapEntry[] => {
  const parsedLocales = window.BFG.locales.whitelistedLocales.split(',');
  return languagesMap.filter((languageEntry) => parsedLocales.includes(languageEntry.locale));
};

export const determineUGTLocale = (): Locale => {
  const BFG = window.BFG; // test workaround to use window BFG mock
  if (!BFG.multiLanguageAssetDetailsEnabled) {
    return Locale.English;
  }

  const userViewOptionsAll = getStorage(StorageTypes.Local, `userViewOptions_${BFG.currentUser.user_id}`);
  const userViewOptions = userViewOptionsAll && userViewOptionsAll[BFG.resource.key];
  const { staticSiteLocale, ugtLocaleDefault } = BFG.locales;
  const ugtLocaleInLocalStorage = userViewOptions?.selectedUGTLocale;
  const ugtLocaleInLocalStorageInWhitelisted = findCurrentLanguageInMap(ugtLocaleInLocalStorage, getAvailableLanguages());
  const staticSiteLocaleInWhitelisted = findCurrentLanguageInMap(staticSiteLocale, getAvailableLanguages());

  // determine best locale to return
  if (ugtLocaleInLocalStorage && ugtLocaleInLocalStorageInWhitelisted) {
    return ugtLocaleInLocalStorage;
  }

  if (staticSiteLocaleInWhitelisted) {
    return staticSiteLocale;
  }

  return ugtLocaleDefault;
};

export const tempAttachmentTemplate = (file: StructuredFileMetadata): FlattenedAttachment => ({
  extension: file.filename.substring(file.filename.lastIndexOf('.')).toLowerCase(),
  key: file.external_id,
  temporaryAttachment: true,
  mimetype: file.mimetype,
  filename: file.filename,
  size: file.size,
  url: file.url,
  source: UploaderSources.Filestack
});

export const tempAttachmentTemplateUppy = (file: Result): FlattenedAttachment => ({
  extension: file.ext,
  key: file.id,
  temporaryAttachment: true,
  mimetype: file.mime,
  filename: file.name,
  size: file.size,
  url: file.url,
  source: UploaderSources.Uppy
});

export const newAssetInitialState = (assetType: AssetTypes): InitialModalEditFormContentState => ({
  asset: null,
  assetKey: '',
  assetType,
  attachments: [],
  customFieldKeys: null,
  flattenedCustomFieldKeyValuesMap: null,
  dependentCustomFields: null,
  printui: null,
  tags: [],
  task: null,
});

export const newAssetEmptyEditState = (): ModalEditFormContentState => (
  {
    aiGeneratedCaptionId: null,
    assetDescription: '',
    assetName: '',
    attachments: [],
    colorData: null,
    externalMediumData: null,
    firstAttachmentKey: '',
    personData: null,
    pressData: null,
    tags: [],
    tagSearchSuggestions: null,
    thumbnailUrl: '',
    fontData: null,
    flattenedCustomFieldKeyValuesMap: null,
    task: {
      assetKey: '',
      createdAt: '',
      deletedAt: null,
      dimensions: '',
      dueDate: '',
      filetype: '',
      taskDescription: '',
      taskPriority: TaskPriorities.Low,
      taskStatus: TaskStatuses.NotStarted,
      taskUsers: [],
      updatedAt: '',
      workspaceKey: ''
    },
    terribleTrumbowygFlag: determineUGTLocale(),
    thumbnailOverride: {},
    watermark: {},
    genericFileData: null,
  }
);

export const getFilenameSansExt = (filename: string, extension: string): string => {
  if (extension) {
    const lowercaseFilename = filename.toLowerCase();
    const extIndex = lowercaseFilename.lastIndexOf(`.${extension}`);
    return filename.substring(0, extIndex);
  }

  return filename;
};

export const handleThumbnailError = (
  event: SyntheticEvent<HTMLImageElement, Event>,
  extension: string,
  name: string,
  isLargeView?: boolean,
  isHoverImage?: boolean,
  isTask?: boolean,
): void => {
  const taskCdnLink = 'https://cdn.bfldr.com/27C9EC93/at/pmp8cjjrr7brbz648rn8nb94/task-creation-file-icon.png';
  const taskAlt = 'Task placeholder image because an attachment needs to be uploaded';
  const source = extension ? `${extension}.svg` : 'file.svg';
  const eventTarget = event.target as HTMLImageElement;
  eventTarget.src = isTask ? taskCdnLink : `${BFG.icon_service_url}/assets/types/${source}`;
  eventTarget.alt = isTask ? taskAlt : name;

  if (isLargeView) {
    eventTarget.style.cssText = 'padding: 25px;';
  }

  // an image inside the attachment item hover preview need a width and height set
  // otherwise the image has a width and height of 0 and 0, and no preview will show
  if (isHoverImage) {
    eventTarget.height = 172;
    eventTarget.width = 172;
  }
};

export const readableSubmissionActions = {
  [Submit.DeletedAsset]: 'Delete asset',
  [Submit.DeletedAttachment]: 'Delete attachment',
  [Submit.DeletedCustomFieldValue]: 'Delete custom field value',
  [Submit.NewAsset]: 'Create new asset',
  [Submit.NewTask]: 'Create new task',
  [Submit.NewCustomFields]: 'Create new custom fields',
  [Submit.Tags]: 'Update tags',
  [Submit.UpdatedAsset]: 'Update asset',
  [Submit.UpdatedAttachment]: 'Update attachment',
  [Submit.UpdatedCustomField]: 'Update custom field',
  [Submit.UpdatedTask]: 'Update task'
};

export const convertSnakeToCamel = (snakeCaseText: string): string => (
  // regex: groups (pairs) of characters with '_' followed by a letter from a-z, case insensitive
  snakeCaseText.replace(/([_][a-z])/ig, (underscoreGroup): string => (
    underscoreGroup.toUpperCase().replace('_', '')
  ))
);

/**
 * Get whether two number arrays or two string arrays are the same.
 * NOTE: Only works with number and string arrays (does NOT work with arrays of objects).
 * There should be no undefined or null items in your array.
 * TODO: consider using a package: react-fast-compare, fast-deep-equal, or fast-equals
 * @param array1 unknown[]
 * @param array2 unknown[]
 * @returns boolean
 */
export const getIsSameArray = (array1: unknown[], array2: unknown[]): boolean => {
  // because we don't have strictNullChecks turned on
  // we need to check null/undefined
  if (array1 === null && Array.isArray(array2)) return false;
  if (array2 === null && Array.isArray(array1)) return false;
  if (array1 === undefined && Array.isArray(array2)) return false;
  if (array2 === undefined && Array.isArray(array1)) return false;

  // if they are both null or undefined, i guess they're the same?
  // once strictNullChecks is turned on, we can remove this
  // as comsuming getIsSameArray won't allow null/undefined then
  if (array1 === null && array2 === null) return true;
  if (array1 === undefined && array2 === undefined) return true;

  // both array1 and array 2 should be arrays once we get down here
  // quick sanity check on the length, can bail if not the same
  if (array1.length !== array2.length) return false;

  // there is no equals method on arrays in js, though there eventually be:
  // https://github.com/tc39/proposal-array-equality#proposal
  const isInArray1 = array1.sort().every((array1Item) => array2.sort().find((array2item) => array1Item === array2item));
  const isInArray2 = array2.sort().every((array2item) => array1.sort().find((array1Item) => array1Item === array2item));

  return isInArray1 && isInArray2;
};

/**
 * Get whether a UpdateCustomFieldValuesPayload has a missing required custom field
 * @param customFieldKeyValuesPayload UpdateCustomFieldValuesPayload
 * @returns boolean
*/
export const getHasMissingRequiredCustomFieldValuesPayload = (customFieldKeyValuesPayload: UpdateCustomFieldValuesPayload): boolean => customFieldKeyValuesPayload.customFieldRequired
  && customFieldKeyValuesPayload.customFieldTouched
  && customFieldKeyValuesPayload.customFieldValue.value === '';

/**
 * Get whether a CustomFieldKeyValuesEntry has a missing required custom field
 * @param customFieldKeyValuesEntry CustomFieldKeyValuesEntry
 * @returns boolean
*/
export const getHasMissingRequiredCustomFieldValuesEntry = (customFieldKeyValuesEntry: CustomFieldKeyValuesEntry): boolean => customFieldKeyValuesEntry.customFieldRequired
  && customFieldKeyValuesEntry.customFieldTouched
  && (customFieldKeyValuesEntry.customFieldValues.length === 0 || customFieldKeyValuesEntry.customFieldValues[0].value === '');

/**
 * Get whether any flattened custom fields have a missing required custom field
 * @param flattenedCustomFieldKeyValuesMap FlattenedCustomFieldKeyValuesMap
 * @returns boolean
 */
export const getHasMissingRequiredCustomFields = (flattenedCustomFieldKeyValuesMap: FlattenedCustomFieldKeyValuesMap): boolean => {
  const hasMissingRequiredCustomField = Object.entries(flattenedCustomFieldKeyValuesMap).find((customField) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    const [_, customFieldKeyValuesEntry] = customField;
    return getHasMissingRequiredCustomFieldValuesEntry(customFieldKeyValuesEntry);
  });
  return !!hasMissingRequiredCustomField;
};
export const sortChildKeysBelowParentKeys = (
  response: CustomFieldKeysListResponse
): CustomFieldKeysResponseObject[] => {
  const allHavePositions = response.data.every(({ attributes }) => attributes.position != null);
  const sortedKeys = response.data.sort((a, b) => {
    if (allHavePositions) {
      return a.attributes.position - b.attributes.position;
    }
    return sortOnPrioritizedThenName(a.attributes, b.attributes);
  });

  const dependentCustomFieldsMap = response.included?.reduce((acc, dcf) => {
    acc[dcf.attributes.child_key] = dcf;
    return acc;
  }, {} as DependentCustomField);

  let organizedCustomKeys: CustomFieldKeysResponseObject[] = sortedKeys;
  if (!!dependentCustomFieldsMap) {
    const fieldKeys = sortedKeys.reduce((acc, key) => {
      if (!dependentCustomFieldsMap[key.id]) acc.parentFields.push(key);
      if (dependentCustomFieldsMap[key.id]) acc.childFields.push(key);
      acc.childFields.sort((a, b) => a.attributes.position - b.attributes.position).reverse();
      return acc;
    }, { parentFields: [], childFields: [] } as SplitParentAndChildCustomFieldKeys);
    organizedCustomKeys = fieldKeys.parentFields;
    fieldKeys.childFields.forEach((childKey) => {
      const childField = dependentCustomFieldsMap[childKey.id];
      const parentIndex = organizedCustomKeys.findIndex(({ id }) => childField.attributes.parent_key === id);
      return organizedCustomKeys.splice(parentIndex + 1, 0, childKey);
    });
  }
  return organizedCustomKeys;
};

export const flattenCustomFieldKeysList = (response: CustomFieldKeysListResponse): FlattenedCustomFieldKeyValuesMap => {
  const organizedCustomKeys = sortChildKeysBelowParentKeys(response).map(
    ({ id, attributes }) => ({ id, ...attributes })
  );
  const sortedKeyValuesMap: FlattenedCustomFieldKeyValuesMap = {};
  organizedCustomKeys.forEach((key) => {
    sortedKeyValuesMap[key.id] = {
      customFieldKey: key,
      customFieldValues: [],
      customFieldRequired: key.required
    };
  });
  return sortedKeyValuesMap;
};
