import {
  ButtonLooks,
  FontIcons,
  StandardButton,
  StandardTable,
  StandardTableColumn,
  StandardTableRow
} from '@brandfolder/react';
import { localizeDate } from '@brandfolder/utilities';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { fetchJson, MAX_PAGE_SIZE, useFetch } from '@api/ApiHelper';
import { PendingInvitationsGetResponse } from '@api/v4/users/pending_invitations';
import { reorderPendingInvitations } from '@components/bulk_management/user-settings/helpers';
import {
  PendingInvitationsCopyIcon
} from '@components/bulk_management/user-settings/invitations/PendingInvitationsCopyIcon';
import {
  PendingInvitationsResendIcon
} from '@components/bulk_management/user-settings/invitations/PendingInvitationsResendIcon';
import { DangerButton, TertiaryIconButton, TextButton } from '@components/library/button';
import { StandardDialog } from '@components/library/dialog';
import { REUSABLE_EMAIL } from '@helpers/emails';
import { getCurrentUserIsSuper } from '@helpers/user';

import '../styles/pending-invitations.scss';

interface PendingInvitationsProps {
  reload: boolean;
  selectedResourceKey: string;
  selectedResourceType: 'organization' | 'brandfolder' | 'collection' | 'portal' | 'brandguide';
  setReload: SetStateDispatch<boolean>;
}

export const PendingInvitations: FunctionComponent<PendingInvitationsProps> = ({
  reload,
  selectedResourceKey,
  selectedResourceType,
  setReload
}) => {
  const [pendingInvitationsDeleteKey, setPendingInvitationsDeleteKey] = useState<string | undefined>();
  const [deletePendingInvitationsOpen, setDeletePendingInvitationsOpen] = useState(false);
  const [deleteAllPendingInvitationsOpen, setDeleteAllPendingInvitationsOpen] = useState(false);
  const [deleteAll, setDeleteAll] = useState({ error: false, loading: false, resolved: false });

  const pendingInvitationsFetch = useFetch<PendingInvitationsGetResponse
  >({
    url: `/api/v4/${selectedResourceType}s/${selectedResourceKey}/invitations`,
    fields: 'created_at',
    params: {
      per: MAX_PAGE_SIZE,
      sort_by: 'created_at', // eslint-disable-line @typescript-eslint/naming-convention
      order: 'DESC'
    }
  });

  const pendingInvitationsDelete = useFetch({
    url: `/api/v4/invitations/${pendingInvitationsDeleteKey}`,
    fetchOnMount: false,
    method: 'DELETE'
  });

  useEffect(() => {
    if (pendingInvitationsDeleteKey) {
      setDeletePendingInvitationsOpen(true);
    }
  }, [pendingInvitationsDeleteKey]);

  useEffect(() => {
    if (pendingInvitationsDelete.response) {
      setPendingInvitationsDeleteKey(undefined);
      setDeletePendingInvitationsOpen(false);
      pendingInvitationsFetch.fetch();
    }
  }, [pendingInvitationsDelete.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reload) {
      pendingInvitationsFetch.fetch();
      setReload(false);
    }
  }, [reload]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteAll.resolved) {
      setDeleteAllPendingInvitationsOpen(false);
      pendingInvitationsFetch.fetch();
    }
  }, [deleteAll.resolved]); // eslint-disable-line react-hooks/exhaustive-deps

  const orderedInvitations = useMemo(() => {
    if (pendingInvitationsFetch.response) {
      return reorderPendingInvitations(pendingInvitationsFetch.response.data);
    }

    return [];
  }, [pendingInvitationsFetch.response]);

  const handleDeleteAll = async (): Promise<void> => {
    setDeleteAll({
      ...deleteAll,
      loading: true
    });

    try {
      const deletePromises = pendingInvitationsFetch.response?.data.map((pendingInvitation) => (fetchJson({
        url: `/api/v4/invitations/${pendingInvitation.id}`,
        method: 'DELETE'
      })));

      await Promise.all(deletePromises);

      setDeleteAll({
        error: false,
        loading: false,
        resolved: true
      });
    } catch (e) {
      setDeleteAll({
        error: true,
        loading: false,
        resolved: false
      });
    }
  };

  const pendingInvitationsDeleteConfirmationDialog = (
    <StandardDialog
      dialogHeaderClassName='delete-confirmation-dialog-header'
      footer={
        <DangerButton
          isLoading={pendingInvitationsDelete.loading}
          onClick={(): void => {
            pendingInvitationsDelete.fetch();
          }}
        >
          <Trans>Delete pending invitation</Trans>
        </DangerButton>
      }
      id="pending-invitations-delete-confirmation-dialog"
      open={deletePendingInvitationsOpen}
      setOpen={setDeletePendingInvitationsOpen}
      showFooter
      title={t`Delete pending invitation`}
      titleIcon={`bff-${FontIcons.Trash}`}
    >
      <p><Trans>Are you sure you want to delete this pending invitation?</Trans></p>
    </StandardDialog>
  );

  const pendingInvitationsDeleteAllConfirmationDialog = (
    <StandardDialog
      dialogHeaderClassName='delete-confirmation-dialog-header'
      footer={
        <DangerButton
          isLoading={deleteAll.loading}
          onClick={(): void => {
            handleDeleteAll();
          }}
        >
          <Trans>Delete all pending invitations</Trans>
        </DangerButton>
      }
      id="pending-invitations-delete-confirmation-dialog"
      open={deleteAllPendingInvitationsOpen}
      setOpen={setDeleteAllPendingInvitationsOpen}
      showFooter
      title={t`Delete all pending invitations`}
      titleIcon={`bff-${FontIcons.Trash}`}
    >
      <p><Trans>Are you sure you want to delete all pending invitations?</Trans></p>
    </StandardDialog>
  );

  const columns: StandardTableColumn[] = [{
    children: <Trans>Email</Trans>,
    heading: <Trans>Email</Trans>,
    rowKey: 'email',
  }, {
    children: <Trans>Permission level</Trans>,
    heading: <Trans>Permission level</Trans>,
    rowKey: 'permission',
  }, {
    children: <Trans>Invited on</Trans>,
    heading: <Trans>Invited on</Trans>,
    rowKey: 'inviteDate',
  }, {
    rowKey: 'resendIcon',
  },
  {
    rowKey: 'copyLinkIcon',
  },
  {
    rowKey: 'deleteIcon',
  }];

  const permissionStringMap = {
    admin: t`Admin`,
    collaborator: t`Collaborator`,
    owner: t`Owner`,
    guest: t`Guest`
  };

  const rows: StandardTableRow[] = orderedInvitations.map((pendingInvitation) => ({
    email: pendingInvitation.attributes.email === REUSABLE_EMAIL
      ? <strong><Trans>Reusable</Trans></strong>
      : pendingInvitation.attributes.email,
    permission: permissionStringMap[pendingInvitation.attributes.permission_level],
    inviteDate: localizeDate(pendingInvitation.attributes.created_at),
    resendIcon: pendingInvitation.attributes.email !== REUSABLE_EMAIL && (
      <PendingInvitationsResendIcon pendingInvitationKey={pendingInvitation.id}/>
    ),
    copyLinkIcon: (
      <PendingInvitationsCopyIcon invitationLink={pendingInvitation.attributes.invitation_url} />
    ),
    deleteIcon: (
      <TertiaryIconButton
        aria-label={t`Delete invitation`}
        className="pending-initations-delete pending-invites-icons"
        icon={FontIcons.Trash}
        onClick={(): void => {
          setDeletePendingInvitationsOpen(true);
          setPendingInvitationsDeleteKey(pendingInvitation.id);
        }}
      />
    ),
  }));

  const pendingInvitationsTitle = <h3><Trans>Pending Invitations</Trans> <small><Trans>(Reusable invitations expire after 30 days)</Trans></small></h3>;

  const heading = getCurrentUserIsSuper(BFG.currentUser) ? (
    <div className="pending-invitations__heading-button-container">
      {pendingInvitationsTitle}
      {pendingInvitationsFetch.response && (
        <StandardButton
          look={ButtonLooks.Warning}
          onClick={(): void => setDeleteAllPendingInvitationsOpen(true)}
        >
          <Trans>Delete all pending invitations</Trans>
        </StandardButton>
      )}
    </div>
  ) : (
    pendingInvitationsTitle
  );

  return rows.length > 0 && (
    <div className="pending-invitations">
      {heading}
      {pendingInvitationsFetch.response && pendingInvitationsDeleteConfirmationDialog}
      {pendingInvitationsFetch.response && pendingInvitationsDeleteAllConfirmationDialog}
      <StandardTable
        caption={t`Pending invitations`}
        columns={columns}
        empty={rows.length === 0}
        emptyContent={t`There are no pending invitations`}
        error={!!pendingInvitationsFetch.error}
        errorContent={(
          <div>
            <p><Trans>There was an error loading pending invitations. Please reload the page.</Trans></p>
            <TextButton onClick={(): void => {pendingInvitationsFetch.fetch();}}>
              <Trans>Try again</Trans>
            </TextButton>
          </div>
        )}
        id="pending-invitations-table"
        loading={pendingInvitationsFetch.loading}
        rows={rows}
        scrollY
      />
    </div>
  );
};
