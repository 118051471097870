import { t } from '@lingui/macro';
import * as Filestack from 'filestack-js';
import { MetadataOptions } from 'filestack-js/build/main/lib/api/file';
import { ClientOptions } from 'filestack-js/build/main/lib/client';
import {
  PickerFileMetadata,
  PickerInstance,
  PickerOptions,
  PickerStoreOptions,
  PickerUploadDoneCallback
} from 'filestack-js/build/main/lib/picker';

import { DEFAULT_FILESTACK_CNAME, FilestackCredentials } from '@api/v4/private/resources/fs_creds';
import { sendAction } from '@helpers/datadog-rum';

interface InitializeFilestackPickerOptions {
  filestackCredentials: FilestackCredentials;
  onUploadDone: PickerUploadDoneCallback;
  pickerOptions?: PickerOptions;
}

/**
 * Initialize Filestack picker
 * @param options InitializeFilestackPickerOptions
 * @returns PickerInstance
 */
export const initializeFilestackPicker = (options: InitializeFilestackPickerOptions): PickerInstance => {
  const { filestackCredentials, onUploadDone, pickerOptions = {} } = options;

  const { key, policy, signature } = filestackCredentials;
  const { onFileUploadFailed } = pickerOptions;

  const filestackOptions: ClientOptions = {
    security: {
      policy,
      signature
    },
    cname: DEFAULT_FILESTACK_CNAME
  };

  const filestack = Filestack.init(key, filestackOptions);

  const handleError = (file: PickerFileMetadata, error: Error): void => {
    if (error?.message?.indexOf('cancel') === -1) {
      sendAction('Error Uploading File to Filestack', {
        tags: { file, error },
        extra: { file, error }
      });
    }

    if (onFileUploadFailed) {
      onFileUploadFailed(file, error);
    } else {
      Notify.create({
        title: t`There was an error uploading your file. Please try again.`,
        type: 'error'
      });
    }
  };

  const defaultOptions: PickerOptions = {
    onUploadDone,
    onFileUploadFailed: handleError,
    disableThumbnails: true,
    disableAltText: true,
    maxFiles: 3000,
    fromSources: ['local_file_system', 'googledrive', 'dropbox', 'box', 'url'],
    globalDropZone: false,
    uploadConfig: {
      // default Filestack timeout for FII (intelligent ingestion) is only 1 minute
      timeout: 180000,
      intelligent: false, // Disabled due to ESC-9161
    }
  };

  const storageOptions = (): PickerStoreOptions => {
    // Printui & Workbench
    if (location.pathname.includes('/workbench/') || location.pathname == '/template_editor') {
      // Isolate into GCS bucket with short file retention, Filestack 'Workbench' account
      return {
        location: 'gcs',
        container: 'bf-storage-filestack-workbench-stage'
      };
    }
    if (BFG.environment !== 'production') {
      // Stage & Dev go to GCS, Filestack 'Boulder Lower' account
      return {
        location: 'gcs',
        container: 'bf-storage-filestack-stage'
      };
    }
    if (!BFG.context.uses_gcs && BFG.context.storageRegion && BFG.context.storageRegion !== BFG.context.defaultStorageRegion) {
      return {
        location: 's3',
        container: `bf-filestack-prod-${BFG.context.storageRegion}`,
        region: BFG.context.storageRegion,
        path: '/'
      };
    }
    return {
      location: 's3'
    };
  };

  const completeOptions: PickerOptions = {
    ...defaultOptions,
    storeTo: storageOptions(),
    ...pickerOptions
  };
  return filestack.picker(completeOptions);
};

interface InitializeFilestackMetadataOptions {
  filestackCredentials: FilestackCredentials;
  handle: string;
  metadataOptions?: MetadataOptions;
}

/**
 * Initialize Filestack metadata
 * @param options InitializeFilestackMetadataOptions
 * @returns  Promise<T>
 */
export const initializeFilestackMetadata = <T>(options: InitializeFilestackMetadataOptions): Promise<T> => {
  const { filestackCredentials, handle, metadataOptions = {} } = options;
  const { key, policy, signature } = filestackCredentials;

  const filestackOptions: ClientOptions = {
    security: {
      policy,
      signature
    },
    cname: DEFAULT_FILESTACK_CNAME
  };

  const defaultOptions: MetadataOptions = {
    filename: true,
    mimetype: true,
    width: true,
    height: true
  };

  const completeOptions: MetadataOptions = {
    ...defaultOptions,
    ...metadataOptions
  };

  const filestack = Filestack.init(key, filestackOptions);
  return filestack.metadata(handle, completeOptions);
};
