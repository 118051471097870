import { useDebounce } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import AccordionSection from './accordionSection';

export const SkuFilter = ({ updateFilters }) => {
  const [searchInput, setSearchInput] = useState(undefined);
  const searchInputDebounced = useDebounce(searchInput, 500);

  const updateSkus = (value) => {
    /* eslint-disable no-useless-escape */
    const str = `(\"${value.trim().replace(/['"]+/g, '').split(/[\s,;\t\n]+/).join('\" OR \"')}\")`;
    setSearchInput(str);
  }

  useEffect(() => {
    // Parent functions don't handle empty strings well
    if (searchInputDebounced === undefined) { return; }

    updateFilters(searchInputDebounced);
  }, [searchInputDebounced]);

  return (
    <AccordionSection title={t`SKU Search`}>
      <React.Fragment>
        <div className="flex-row">
          <div className="filter-search-row sku">
            <span><Trans>Terms:</Trans></span>
            <div className="sku-search-container">
              <span className="bff-search" />
              <textarea
                onChange={(e) => { updateSkus(e.target.value) }}
                rows={3}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    </AccordionSection>
  );
}

SkuFilter.propTypes = {
  updateFilters: PropTypes.func.isRequired,
};

export default SkuFilter;