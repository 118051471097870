/* eslint-disable @typescript-eslint/naming-convention */
import { Asset, AssetData, LinkObject, WatermarkObject } from '@api/v4/assets/assetTypes';
import { ReducerState } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { toPascalCase } from '@components/library/utils/helpers';
import { CreateAttachmentProperties } from './attachmentChangeEngine';

// these get placed in the API call directly
// try and keep field names the same as ones returned
// from api
export interface AssetChanges {
  attachments: CreateAttachmentProperties[];
  data: AssetData;
  description: string;
  name: string;
  thumbnail_override: LinkObject;
  watermark: WatermarkObject;
}

enum AssetValues {
  Attachments,
  Data,
  Description,
  Name,
  ThumbnailOverrideChangeKey,
  WatermarkChangeKey
}

const getNewAttachments = (changes: Partial<AssetChanges>, state: ReducerState): Partial<AssetChanges> => {
  const { editState, initialData } = state;
  const newAttachments: CreateAttachmentProperties[] = [];
  editState.attachments.forEach((editAttachment) => {
    const matchingAttachment = initialData.attachments.find((initialAttachment) => initialAttachment.key === editAttachment.key);
    if (!matchingAttachment) {
      const { key, filename, position, source, url  } = editAttachment;
      newAttachments.push({ key, filename, position, source, url });
    }
  });

  return newAttachments.length > 0
    ? {
      ...changes,
      attachments: newAttachments
    }
    : changes;
};

const getAssetStringChanges = (changes: Partial<AssetChanges>, state: ReducerState, assetDetailString: keyof Asset): Partial<AssetChanges> => {
  const editedStateKey = `asset${toPascalCase(assetDetailString)}`;
  const initialAssetDetail = (state.initialData.asset && state.initialData.asset[assetDetailString]) || '';

  if (initialAssetDetail !== state.editState[editedStateKey]) {
    return {
      ...changes,
      [assetDetailString]: state.editState[editedStateKey]
    };
  }
  return changes;
};

const assetChangeMap = {
  [AssetValues.Attachments]: getNewAttachments,
  [AssetValues.Description]: (changes: Partial<AssetChanges>, state: ReducerState): Partial<AssetChanges> => getAssetStringChanges(changes, state, 'description'),
  [AssetValues.Name]: (changes: Partial<AssetChanges>, state: ReducerState): Partial<AssetChanges> => getAssetStringChanges(changes, state, 'name'),
  [AssetValues.ThumbnailOverrideChangeKey]: (changes: Partial<AssetChanges>, state: ReducerState): Partial<AssetChanges> => {
    const urlChanges = state.editState.thumbnailOverride.url !== state.initialData.asset?.thumbnail_override.url;
    return (urlChanges)
      ? { ...changes, thumbnail_override: { ...state.editState.thumbnailOverride } }
      : changes;
  },
  [AssetValues.WatermarkChangeKey]: (changes: Partial<AssetChanges>, state: ReducerState): Partial<AssetChanges> => {
    const gravityChanged = state.editState.watermark.gravity !== state.initialData.asset?.watermark?.gravity;
    // need to null check initialData.asset?.watermark? because change engine fires before we transform the asset
    const watermarkUrlChanged = state.editState.watermark.url !== state.initialData.asset?.watermark?.url;

    return (gravityChanged || watermarkUrlChanged)
      ? { ...changes, watermark: { ...state.editState.watermark } }
      : changes;
  }
};

export const getAssetChanges = (state: ReducerState): Partial<AssetChanges> | false => {
  let changes: Partial<AssetChanges> = {};
  changes = Object.keys(assetChangeMap).reduce((prevChanges, key) => assetChangeMap[key](prevChanges, state), changes);
  return Object.keys(changes).length > 0 && changes;
};
