/* NOTE: This files is currently being used only in the Org Search Results page
however it should have UI parity with the main.jsx file in this repo. Eventually,
there should be one file for assetDetails.
*/
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import FontClass from '@components/asset/shared/fontClass';

import Attachment from '../shared/attachmentListItem';

import AssetTags from './assetTags';
import ColorDetails from './colorDetails';
import { CustomFieldsAccordion } from './CustomFieldsAccordion';
import { useConfigurableExpiryUrls } from './helpers/constants'
import AssetLinks from './links/asset_links';
import { OpenInNewTab } from './OpenInNewTab';
import PersonDetails from './personDetails';
import ShareAsset from './shareAsset';
import TextDetails from './text';

/**
 * NOTE: Anytime you do work in this component make sure there is parity with
 * app/javascript/components/asset/modal/tabs/asset_details/AssetDetails.tsx.
 * The UI should be similar, however the UI here is more limited in this file
 * At some point these should become one component. Right now they are separated
 * because AssetDetails.tsx is getting the majority of its information from Rails,
 * whereas this component is getting its information from an ajax call, which does not support
 * everything we are currently getting from rails for the more complete details tab
 */
class AssetDetails extends React.Component {
  get assetBackgroundColor() {
    const backgroundColor = this.props.asset.attributes.background_color;
    return backgroundColor && backgroundColor !== "default"
      ? { background: backgroundColor }
      : { backgroundColor: "transparent" };
  }

  parseDate(date_string) {
    const day = date_string.substr(8, 2);
    const month = date_string.substr(5, 2);
    const year = date_string.substr(0, 4);

    return `${month}/${day}/${year}`;
  }

  backgroundColor() {
    const asset = this.props.asset;
    if (asset.type === "colors") {
      return { backgroundColor: asset.attributes.html, height: "450px" };
    }
    return undefined;
  }

  renderAttachments() {
    const { asset, attachments, activeImage, updateActiveImage } = this.props;

    return attachments.map((attachment) => (
      <Attachment
        key={attachment.id}
        assetViewOnly={asset.attributes.view_only}
        attachment={attachment}
        attachmentKey={attachment.id}
        isActive={attachment.id === activeImage.id}
        isDownloadable={asset.attributes.is_downloadable}
        updateActiveImage={updateActiveImage}
      />
    ));
  }

  renderHeader() {
    const { asset, activeImage } = this.props;
    const { mimetype, url } = activeImage ? activeImage.attributes : { mimetype: null, url: null };

    if (
      mimetype
      && mimetype.indexOf("video") > -1 && activeImage.attributes
      && activeImage.attributes.url
    ) {
      const type = `video/ ${url.substr(url.lastIndexOf(".") + 1)}`;
      return (
        <div className="m-show-full-size-display">
          {type === "video/mov" ? (
            <video
              className="video-js s-expand-view"
              controls
              height="auto"
              id="advanced-video"
              src={activeImage.attributes.url}
              width="100%"
            />
          ) : (
            <video
              className="video-js s-expand-view"
              controls
              height="auto"
              id="advanced-video"
              preload="auto"
              width="100%"
            >
              <source src={activeImage.attributes.url} type={type} />
            </video>
          )}
        </div>
      );
    }

    const retinaThumbnailUrl = activeImage.attributes.configurable_expiry_view_thumbnail_retina?.trim() || activeImage.attributes.view_thumbnail_retina
    const thumbnailUrl = activeImage.attributes.configurable_expiry_thumbnail_url?.trim() || activeImage.attributes.thumbnail_url

    switch (asset.type) {
      case "fonts":
        return (
          <div className="m-show-full-size-display">
            <FontClass asset={asset}>
              Aa Bb Cc Dd Ee Ff Gg Hh Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu Vv
              Ww Xx Yy Zz 0123456789
            </FontClass>
          </div>
        );
      case "colors":
        return (
          <div
            className="m-show-full-size-display-color"
            style={this.backgroundColor()}
          />
        );
      default: {
        const isDownloadable = asset.attributes.is_downloadable !== false;

        return (
          <div
            className="m-show-full-size-display"
            style={this.assetBackgroundColor}
          >
            {(activeImage && isDownloadable) ? (
              <OpenInNewTab
                asset={asset}
                className="s-expand-view"
                isDownloadable={isDownloadable}
                isImage={true}
                isPreviewRenderError={false}
                selectedAttachment={activeImage.attributes}
                selectedAttachmentKey={activeImage.id}
                threeSixtyViewer={false}
                useConfigurableExpiryUrls={useConfigurableExpiryUrls}
              >
                <img alt="thumbnail" data-private src={retinaThumbnailUrl} />
              </OpenInNewTab>
            ) : <img alt="thumbnail" data-private src={thumbnailUrl} />
            }
            {activeImage && isDownloadable && (
              <OpenInNewTab
                asset={asset}
                className="view-asset-new-tab-link"
                isDownloadable={isDownloadable}
                isPreviewRenderError={false}
                selectedAttachment={activeImage.attributes}
                selectedAttachmentKey={activeImage.id}
                threeSixtyViewer={false}
                useConfigurableExpiryUrls={useConfigurableExpiryUrls}
              />
            )}
          </div>
        );
      }
    }
  }

  renderDetailedOrGeneric() {
    const { asset } = this.props;
    const formattedDescription = asset.attributes.description
      ? asset.attributes.description.replace(/\n/g, "<br>")
      : '';

    switch (asset.type) {
      case "people":
        return (
          <PersonDetails
            bio={asset.attributes.asset_data.bio}
            email={asset.attributes.asset_data.email}
            linkedin={asset.attributes.asset_data.linkedin}
            name={asset.attributes.name}
            phoneNumber={asset.attributes.asset_data.phone_number}
            title={asset.attributes.asset_data.title}
            twitter={asset.attributes.asset_data.twitter}
          />
        );
      // TODO: implement PressDetails
      case "press":
        return (
          <div className="m-show-description">
            {asset.attributes.asset_data.url ? (
              <a href={asset.attributes.asset_data.url} target="blank">
                {asset.attributes.asset_data.url}
              </a>
            ) : (
              ""
            )}
            {asset.attributes.asset_data.published_date ? (
              <div> {asset.attributes.asset_data.published_date} </div>
            ) : (
              ""
            )}
            <div className="m-show-description s-asset-description">
              <span className="s-asset-details-title modal-label">Description</span>
              <p dangerouslySetInnerHTML={{ __html: formattedDescription }} />
            </div>
          </div>
        );
      case "colors":
        return (
          <div className="m-show-description">
            {asset.attributes.description}
          </div>
        );
      default:
        if (formattedDescription.length > 0) {
          return (
            <div className="m-show-description">
              <span className="s-asset-details-title modal-label">Description</span>
              <p dangerouslySetInnerHTML={{ __html: formattedDescription }} />
            </div>
          );
        }
    }
    return undefined;
  }

  renderTags() {
    if (this.props.tags.length > 0) {
      return <AssetTags orgSearchPage tags={this.props.tags} />;
    }
    return undefined;
  }

  renderUpdatedCreated() {
    const { updated_at, created_at } = this.props.asset.attributes;
    const updatedAndCreated = updated_at.length > 0 && created_at.length > 0;
    const updatedOrCreated = updated_at.length > 0 || created_at.legnth > 0;
    const updatedAtDate = updated_at.length > 0 && this.parseDate(updated_at);
    const createdAtDate = created_at.length > 0 && this.parseDate(created_at);

    if (updatedOrCreated) {
      return (
        <div className="s-modal-updated-at">
          {updated_at.length > 0 && <Trans>Last updated on {updatedAtDate}</Trans>}
          {!!updatedAndCreated && <> &middot; </>}
          {created_at.length > 0 && <Trans>Created on {createdAtDate}</Trans>}
        </div>
      );
    }

    return undefined;
  }

  renderEmbedView() {
    const { asset, libraryName } = this.props;
    return (
      <div className="flex-one-third s-asset-attachments">
        <ul className="s-download-attachments-container s-attachment-conversion s-color-org-container clickable-attachments">
          {this.renderAttachments()}
          {asset.type === "colors" ? <ColorDetails assetData={asset.attributes.asset_data} /> : ''}
          {(asset.type === "fonts" || asset.type === "colors" || asset.type === "external_media") && (
            <AssetLinks asset={asset} />
          )}
        </ul>
        <ul className="s-download-attachments-container actions-container">
          <li className="s-asset-action-item s-download-menu-spacing t-show-modal-download-action">
            <a
              className="noline"
              href={asset.attributes.best_link_for}
              rel="noopener noreferrer"
              target="_blank"
            >
              <button
                className="button primary lg"
                type="button"
              >
                <Trans>View in {libraryName}</Trans>
              </button>
            </a>
          </li>
        </ul>
      </div>
    );
  }

  render() {
    const { asset, closeModal, libraryName } = this.props;

    if (BFG.embedView) {
      return this.renderEmbedView();
    }

    if (asset.type === "texts") {
      return (
        <div className="s-text-details flex-two-thirds s-asset-details bf-scroll-element">
          <TextDetails
            asset={asset.attributes}
            assetKey={asset.id}
            closeModal={closeModal}
            formattedDescription={asset.attributes.formatted_description}
            orgSearchPage
            tags={this.props.tags}
            updatedAndCreated={this.renderUpdatedCreated()}
          />
        </div>
      );
    }

    return (
      <div className="flex-wrapper m-asset-description-show">
        <div className="flex-two-thirds s-asset-details bf-scroll-element">
          {this.renderHeader()}
          {this.renderUpdatedCreated()}
          {this.renderTags()}
          {this.renderDetailedOrGeneric()}
          <CustomFieldsAccordion assetKey={asset.id} closeModal={closeModal} count={6} />
        </div>
        <div className="flex-one-third s-asset-attachments bf-scroll-element">
          <ul className="s-download-attachments-container s-attachment-conversion s-color-org-container clickable-attachments">
            {this.renderAttachments()}
            {asset.type === "colors" && (
              <ColorDetails assetData={asset.attributes.asset_data} />
            )}
            {(asset.type === "fonts" || asset.type === "colors" || asset.type === "external_media") && (
              <AssetLinks
                assetData={asset.attributes.asset_data}
                assetName={asset.attributes.name}
                assetType={asset.type}
              />
            )}
          </ul>
          <ul className="s-download-attachments-container actions-container">
            {this.props.asset.attributes.is_downloadable && !this.props.asset.attributes.view_only && (
              <li className="s-asset-action-item t-show-modal-share-action">
                <ShareAsset assetKey={asset.id} />
              </li>
            )}
            <li className="s-asset-action-item s-download-menu-spacing t-show-modal-download-action">
              <a
                className="noline"
                href={asset.attributes.best_link_for}
                rel="noopener noreferrer"
                target="_blank"
              >
                <button
                  className="button primary lg"
                  type="button"
                >
                  <Trans>View in {libraryName}</Trans>
                </button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

AssetDetails.propTypes = {
  asset: PropTypes.shape({
    attributes: PropTypes.shape({
      background_color: PropTypes.shape({}),
      best_link_for: PropTypes.string,
      created_at: PropTypes.string.isRequired,
      is_downloadable: PropTypes.bool,
      updated_at: PropTypes.string.isRequired,
      view_only: PropTypes.bool
    }),
    id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  closeModal: PropTypes.func.isRequired,
  libraryName: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateActiveImage: PropTypes.func.isRequired,
};

AssetDetails.defaultProps = {
  libraryName: 'Brandfolder'
}

export default AssetDetails;
