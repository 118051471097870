/* eslint-disable @typescript-eslint/naming-convention */
import {
  FilestackFileMetadata,
  SnakifiedFilestackFileMetadata,
  StructuredFileMetadata,
  UploaderExternalIds,
  UploaderSources,
  UppyFileMetadata,
} from '@api/uploaders';
import { snakifyObjectKeys } from '@components/library/utils';

/**
 * Convert a Filestack uploaded file to a structured file for consumption by Brandfolder.
 * @param file FilestackFileMetadata
 * @returns StructuredFileMetadata
 */
export const structureFilestackFile = (file: FilestackFileMetadata): StructuredFileMetadata => {
  const structureFile = snakifyObjectKeys<FilestackFileMetadata, StructuredFileMetadata>(file);

  structureFile.data = {};
  structureFile.data.filepicker_client = file.source;
  structureFile.data.filepicker_upload_id = file.uploadId;
  structureFile.external_id = `${UploaderExternalIds.Filestack}|${file.handle}`;
  structureFile.folder_path = file.originalPath;
  structureFile.source = UploaderSources.Filestack;

  delete (structureFile as SnakifiedFilestackFileMetadata).original_file;
  delete (structureFile as SnakifiedFilestackFileMetadata).status;

  return structureFile;
};

/**
 * Convert Filestack uploaded files to a structured files for consumption by Brandfolder.
 * @param files FilestackFileMetadata[]
 * @returns StructuredFileMetadata[]
 */
export const structureFilestackFiles = (files: FilestackFileMetadata[]): StructuredFileMetadata[] => files.map((file) => structureFilestackFile(file));

/**
 * Convert an Uppy uploaded attachment to a structured file for consumption by Brandfolder.
 * @param file UppyFileMetadata
 * @returns StructuredFileMetadata
 */
export const structureUppyAttachment = (file: UppyFileMetadata): StructuredFileMetadata => ({
  alt: file.original_name,
  external_id: `${UploaderExternalIds.TransloadIt}|${file.id}`,
  filename: file.original_name,
  folder_path: file.original_path,
  mimetype: file.mime,
  height: file.meta.height,
  size: file.size,
  source: UploaderSources.Uppy,
  url: file.ssl_url,
  width: file.meta.width
});

/**
 * Convert Uppy uploaded attachments to a structured files for consumption by Brandfolder.
 * @param files UppyFileMetadata[]
 * @returns StructuredFileMetadata[]
 */
export const structureUppyAttachments = (files: UppyFileMetadata[]): StructuredFileMetadata[] => files.map((file) => (structureUppyAttachment(file)));
